import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export type ImageAssetGenerationStatusRequestData =
  operations["media_api_get_image_asset_generation_status"]["requestBody"]["content"]["application/json"];

export type ImageAssetGenerationStatusResponse =
  operations["media_api_get_image_asset_generation_status"]["responses"][200]["content"]["application/json"] & {
    error?: string;
  };

export type useGetImageAssetGenerationStatusProps = {
  brandId: string;
  jobSessionId: string | null;
};

const useGetImageAssetGenerationStatus = ({
  brandId,
  jobSessionId,
}: useGetImageAssetGenerationStatusProps) => {
  const { data } = useQuery<ImageAssetGenerationStatusResponse>({
    queryKey: ["image-asset-generation-status", brandId, jobSessionId],
    queryFn: async (): Promise<ImageAssetGenerationStatusResponse> => {
      const requestBody: ImageAssetGenerationStatusRequestData = {
        brand_id: brandId,
        job_session_id: jobSessionId!,
      };
      const response = await axios
        .post("/api/v1/media/assets/images/generation-status", requestBody, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        })
        .catch((error) => {
          console.log("Error in fetching image generation status", error);
          return {
            data: {
              is_finished: true,
              error: "Failed to generate image",
              image_asset: null,
            },
          };
        });
      return response.data;
    },
    enabled: jobSessionId !== null,
    refetchInterval: 3000,
    retry: false,
    staleTime: Infinity,
  });
  return data;
};

export default useGetImageAssetGenerationStatus;
