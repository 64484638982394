import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export type GenerateImageAssetBackgroundPromptRequestData =
  operations["media_api_generate_image_asset_background_prompt"]["requestBody"]["content"]["application/json"];

export type GenerateImageAssetBackgroundPromptResponse =
  operations["media_api_generate_image_asset_background_prompt"]["responses"][200]["content"]["application/json"];

const useGenerateImageAssetBackgroundPrompt = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: GenerateImageAssetBackgroundPromptResponse) => void;
  onError?: (error: any) => void;
}) => {
  const mutation = useMutation<
    GenerateImageAssetBackgroundPromptResponse,
    Error,
    GenerateImageAssetBackgroundPromptRequestData
  >({
    mutationFn: async (
      params: GenerateImageAssetBackgroundPromptRequestData
    ): Promise<GenerateImageAssetBackgroundPromptResponse> => {
      const { data } = await axios.post(
        "/api/v1/media/assets/images/generate-background-prompt",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (!data.success || data.prompt === null) {
        alert("There was an issue with your request. Try again later.");
        return;
      }
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
  });
  return {
    generateBackgroundPromptMutation: mutation.mutate,
    isGenerating: mutation.isPending,
  };
};

export default useGenerateImageAssetBackgroundPrompt;
