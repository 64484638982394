import PreviewEmailDialog from "../dialogs/PreviewEmailDialog";
import SendTestEmailDialog from "../dialogs/SendTestEmailDialog";
import * as Popover from "@radix-ui/react-popover";
import { Flex, Select, Text, Theme } from "@radix-ui/themes";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import styled from "styled-components";
import { CheckIcon, ChevronDownIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import EditorTopBar from "~/components/core/editor/layout/EditorTopBar";
import PublishCreativeButton from "~/components/core/editor/layout/top-bar/PublishCreativeButton";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";
import useEmailCreativePreviewQuery from "~/hooks/emails/useEmailCreativePreviewQuery";
import { CAMPAIGNS_ROUTE } from "~/routes/constants";
import {
  useEmailState,
  useSetActiveEmailId,
  useSetIsEmailDirty,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import useUpdateEmailCreative from "~/routes/intern/email_editor/hooks/useUpdateEmailCreative";
import usePreventDataLoss from "~/utils/usePreventDataLoss";

const SelectTrigger = styled(Select.Trigger)`
  font-size: 18px;
  font-weight: 500;
`;

const PopoverContent = styled(Popover.Content)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd7d7;
`;

const MenuItem = styled(Flex)`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
`;

const EmailEditorTopBar = () => {
  const { campaignId } = useParams();
  const email = useEmailState();
  const setActiveEmailId = useSetActiveEmailId();
  const setIsDirty = useSetIsEmailDirty();

  const [previewEmailDialogOpen, setPreviewEmailDialogOpen] = useState(false);
  const [isSendTestDialogOpen, setIsSendTestDialogOpen] = useState(false);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  // Mutations and Queries
  const { data: campaignDetail, isLoading: isCreativesLoading } =
    useGetCampaignDetails({ campaignId: campaignId });
  const creativesData = campaignDetail?.email_creatives ?? [];
  const variant = email.variants[email.currentVariantIndex];
  const updateEmailCreativeMutation = useUpdateEmailCreative({
    onSuccess: () => {
      saveModalOnSucceed();
      setIsDirty(false);
    },
    onError: () => {
      saveModalOnFail();
    },
  });

  const { previewHtml, refetch } = useEmailCreativePreviewQuery(email.id);

  const {
    component: saveStateModal,
    onSucceed: saveModalOnSucceed,
    onFail: saveModalOnFail,
  } = usePreventDataLoss({
    enabled: email.isDirty,
    onProceed: () => mutate(), // Changed to arrow function to avoid hoisting issues
    isLoading: updateEmailCreativeMutation.isPending,
    description:
      "You have made some changes to the email, would you like to save them\
        before closing this email ?",
  });

  const emailStateRef = useRef(email);
  useEffect(() => {
    emailStateRef.current = email;
  }, [email]);

  const mutate = useCallback((): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        const email = emailStateRef.current;
        const variant = email.variants[email.currentVariantIndex];
        updateEmailCreativeMutation.mutate(
          {
            id: email.id,
            variant_id: variant?.id ?? "",
            name: email.name,
            subtitle: email.subtitle,
            description: email.description,
            audiences: email.audiences,
            excluded_audiences: email.excluded_audiences,
            sections: variant?.sections // Manually spread because we have to update AND create
              ?.filter((section) => section.type !== null)
              ?.map((section, index) => {
                return {
                  ...section,
                  id: section.id.startsWith("new-section-") ? "" : section.id, // set empty string for new sections
                  index: index,
                };
              })
              ?.filter(Boolean),
            campaignId,
          },
          {
            onSuccess: () => {
              saveModalOnSucceed();
              setIsDirty(false);
              resolve();
            },
            onError: () => {
              saveModalOnFail();
              reject();
            },
          }
        );
      }, 50);
    });
  }, [
    updateEmailCreativeMutation,
    email,
    variant,
    campaignId,
    saveModalOnSucceed,
    saveModalOnFail,
    setIsDirty,
  ]);

  const selectEmailComponent = creativesData.length > 0 && (
    <Select.Root
      onValueChange={setActiveEmailId}
      defaultValue={creativesData[0].id}
      size="2"
    >
      <SelectTrigger
        disabled={isCreativesLoading}
        variant="ghost"
        color="gray"
      />
      <Select.Content color="gray" highContrast position="popper">
        {creativesData.map((creative) => (
          <Select.Item key={creative.id} value={creative.id}>
            <Text size={"4"} weight={"medium"}>
              {creative.title}
            </Text>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );

  const previewTestDropdown = (
    <Flex gap="12px">
      <Popover.Root open={isActionMenuOpen} onOpenChange={setIsActionMenuOpen}>
        <Popover.Trigger asChild>
          <AppButton
            variant="outlined"
            disabled={updateEmailCreativeMutation.isPending}
          >
            Actions
            <ChevronDownIcon strokeWidth={1.5} size={20} />
          </AppButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Theme>
            <PopoverContent sideOffset={5} align="start">
              <Flex direction={"column"} gap="2">
                <MenuItem
                  onClick={async () => {
                    if (email.isDirty) {
                      try {
                        await mutate();
                      } catch (e) {
                        return;
                      }
                    }
                    setPreviewEmailDialogOpen(true);
                  }}
                >
                  <Text>Preview</Text>
                </MenuItem>
                <MenuItem
                  style={{
                    cursor: updateEmailCreativeMutation.isPending
                      ? "not-allowed"
                      : "pointer",
                  }}
                  onClick={async () => {
                    setIsActionMenuOpen(false);
                    let finalHtml = previewHtml;
                    if (email.isDirty) {
                      try {
                        await mutate();

                        toast.info("Downloading HTML");

                        const { data } = await refetch({
                          cancelRefetch: false,
                        });

                        if (data) {
                          finalHtml = data;
                        }
                      } catch (e) {
                        toast.error("Failed to download HTML");
                        return;
                      }
                    }

                    if (finalHtml) {
                      const blob = new Blob([finalHtml], {
                        type: "text/html",
                      });
                      const url = URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.href = url;
                      a.download = `${email.name}.html`;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                      URL.revokeObjectURL(url);
                    }
                  }}
                >
                  <Text>Download HTML</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsSendTestDialogOpen(true);
                  }}
                >
                  <Text>Send Test</Text>
                </MenuItem>
              </Flex>
            </PopoverContent>
          </Theme>
        </Popover.Portal>
      </Popover.Root>

      {email.published_at ? (
        <AppButton
          variant="outlined"
          style={{ background: "var(--primary-white)" }}
          disabled
        >
          <CheckIcon /> Published
        </AppButton>
      ) : (
        <>
          <PublishCreativeButton
            campaignId={campaignId ?? null}
            isDisabled={updateEmailCreativeMutation.isPending || email.isDirty}
            preselectedCreativeIds={{
              emails: [email.id],
            }}
          />
          <AppButton
            variant="primary"
            radius="full"
            onClick={() => mutate().catch(() => {})}
            disabled={updateEmailCreativeMutation.isPending || !email.isDirty}
          >
            {updateEmailCreativeMutation.isPending ? "Saving..." : "Save"}
          </AppButton>
        </>
      )}
    </Flex>
  );

  return (
    <>
      <EditorTopBar
        backButtonDestination={CAMPAIGNS_ROUTE}
        left={
          (creativesData.length ?? 0) > 1 ? (
            selectEmailComponent
          ) : (
            <Text size={"4"} weight={"medium"}>
              {creativesData?.[0]?.title}
            </Text>
          )
        }
        center={<></>}
        right={previewTestDropdown}
      />

      <PreviewEmailDialog
        open={previewEmailDialogOpen}
        onOpenChange={setPreviewEmailDialogOpen}
        isLoading={updateEmailCreativeMutation.isPending}
      />
      <SendTestEmailDialog
        open={isSendTestDialogOpen}
        onOpenChange={setIsSendTestDialogOpen}
        saveEmail={mutate}
      />
      {saveStateModal}
    </>
  );
};

export default EmailEditorTopBar;
