import InternMultiSelectMenu from "./InternMultiSelectMenu";
import { ImageAssetCategory, operations } from "@openapi";
import { Button, Container, Dialog, Flex } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type ReclassifyImageAssetsParams =
  operations["intern_api_reclassify_image_assets"]["requestBody"]["content"]["application/json"];

type ReclassifyImageAssetsResponse =
  operations["intern_api_reclassify_image_assets"]["responses"][200]["content"]["application/json"];

interface InternReclassifyImageAssetDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
}

const InternReclassifyImageAssetDialog: React.FC<
  InternReclassifyImageAssetDialogProps
> = ({ isDialogOpen, setIsDialogOpen }) => {
  const activeBrandID = useActiveBrandID();

  const [categories, setCategories] = useState<ImageAssetCategory[]>(
    Object.values(ImageAssetCategory).filter(
      (value) =>
        value !== ImageAssetCategory.other && value !== ImageAssetCategory.logo
    )
  );

  const [isLoading, setIsLoading] = useState(false);

  const mutation = useMutation<
    ReclassifyImageAssetsResponse,
    Error,
    ReclassifyImageAssetsParams
  >({
    mutationFn: async (
      params: ReclassifyImageAssetsParams
    ): Promise<ReclassifyImageAssetsResponse> => {
      setIsLoading(true);
      const { data } = await axios.post(
        "/api/v1/intern/assets/images/reclassify",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setIsDialogOpen(false);
      alert("Reclassification job started successfully!");
    },
    onError: (error) => {
      setIsLoading(false);
      alert(error);
    },
  });

  const handleClick = () => {
    if (categories.length === 0) {
      alert("Select one or more categories to reclassify.");
      return;
    }
    mutation.mutate({
      brand_id: activeBrandID,
      categories: categories,
    });
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Container>
        <Dialog.Content>
          <Dialog.Title>Reclassify All Images</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Kick off a job to reclassify all images with the current image
            classification model. This is useful for when we improve the
            classification models and need to bulk reclassify.
          </Dialog.Description>
          <Flex direction="column" gap="3" width="100%">
            <InternMultiSelectMenu
              title="Select Categories"
              items={Object.values(ImageAssetCategory)
                .filter(
                  (value) =>
                    value !== ImageAssetCategory.background_image &&
                    value !== ImageAssetCategory.logo &&
                    value !== ImageAssetCategory.product_cutout &&
                    value !== ImageAssetCategory.hero
                )
                .map((value) => ({
                  value: value,
                  label: value,
                }))}
              value={categories}
              onValueChange={(value) => {
                const enumValues = value.map(
                  (value) =>
                    ImageAssetCategory[value as keyof typeof ImageAssetCategory]
                );
                setCategories(
                  enumValues.filter((value) => value !== undefined)
                );
              }}
            />
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button onClick={handleClick} loading={isLoading}>
              Start
            </Button>
          </Flex>
        </Dialog.Content>
      </Container>
    </Dialog.Root>
  );
};

export default InternReclassifyImageAssetDialog;
