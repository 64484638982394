import {
  ImageAssetOrientation,
  ImageAssetOperation,
  ImageAssetVerticalAlignment,
  ImageAssetHorizontalAlignment,
} from "@openapi";
import {
  Button,
  Dialog,
  Flex,
  Heading,
  Select,
  TextArea,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { Sparkles } from "lucide-react";
import { useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGenerateImageAssetBackgroundPrompt from "~/hooks/media/useGenerateImageAssetBackgroundPrompt";
import useGenerateImageAssetWithStatus from "~/hooks/media/useGenerateImageAssetWithStatus";

interface InternGenerateImageAssetDialogProps {
  assetID: string | null;
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  queryKey?: any[];
}

const InternGenerateImageAssetDialog: React.FC<
  InternGenerateImageAssetDialogProps
> = ({ assetID, isDialogOpen, setIsDialogOpen, queryKey }) => {
  const queryClient = useQueryClient();

  const activeBrandID = useActiveBrandID();

  const [operation, setOperation] = useState<ImageAssetOperation>(
    ImageAssetOperation.generate_hero
  );

  const [orientation, setOrientation] = useState<ImageAssetOrientation>(
    ImageAssetOrientation.landscape
  );

  const [backgroundPrompt, setBackgroundPrompt] = useState<string | null>(null);

  const [subjectHorizontalAlignment, setSubjectHorizontalAlignment] =
    useState<ImageAssetHorizontalAlignment | null>(null);

  const [subjectVerticalAlignment, setSubjectVerticalAlignment] =
    useState<ImageAssetVerticalAlignment | null>(null);

  const {
    generateBackgroundPromptMutation,
    isGenerating: isBackgroundPromptGenerating,
  } = useGenerateImageAssetBackgroundPrompt({
    onSuccess(data) {
      setBackgroundPrompt(data.prompt);
    },
    onError(error) {
      alert(error);
    },
  });

  const { generateImageMutation, isGenerating } =
    useGenerateImageAssetWithStatus({
      onScheduleError(error) {
        alert(error);
      },
      onGenerationSuccess(data) {
        setIsDialogOpen(false);
        if (queryKey) {
          queryClient.invalidateQueries({
            queryKey,
          });
        }
      },
      onGenerationError(error) {
        alert(error);
      },
    });

  return (
    <Dialog.Root
      open={isDialogOpen && assetID !== null}
      onOpenChange={setIsDialogOpen}
    >
      <Dialog.Content>
        <Dialog.Title>Generate Image</Dialog.Title>
        <Dialog.Description size="2">
          Use AI to generate a new image asset in the specified orientation.
          Landscape is 1920x1080 and portrait is 1080x1350. This may take up to
          20 seconds depending on the chosen operation.
        </Dialog.Description>
        <Flex
          direction="column"
          gap="8px"
          style={{ marginTop: "8px", marginBottom: "8px" }}
        >
          <Flex direction="column" gap="4px">
            <Heading size="1">Operation</Heading>
            <Select.Root
              defaultValue={operation}
              onValueChange={(value) =>
                setOperation(value as ImageAssetOperation)
              }
            >
              <Select.Trigger style={{ width: "100%" }} />
              <Select.Content>
                <Select.Group>
                  {Object.values(ImageAssetOperation).map((operation) => (
                    <Select.Item key={operation} value={operation}>
                      {operation}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </Flex>
          <Flex direction="column" gap="4px">
            <Heading size="1">Orientation</Heading>
            <Select.Root
              defaultValue={orientation}
              onValueChange={(value) =>
                setOrientation(value as ImageAssetOrientation)
              }
            >
              <Select.Trigger style={{ width: "100%" }} />
              <Select.Content>
                <Select.Group>
                  {Object.values(ImageAssetOrientation).map((orientation) => (
                    <Select.Item key={orientation} value={orientation}>
                      {orientation}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </Flex>
          <Flex direction="column" gap="4px">
            <Heading size="1">Subject Horizontal Alignment</Heading>
            <Select.Root
              defaultValue="auto"
              onValueChange={(value) =>
                setSubjectHorizontalAlignment(
                  value === "auto"
                    ? null
                    : (value as ImageAssetHorizontalAlignment)
                )
              }
            >
              <Select.Trigger style={{ width: "100%" }} />
              <Select.Content>
                <Select.Group>
                  <Select.Item key="auto" value={"auto"}>
                    {"auto"}
                  </Select.Item>
                  {Object.values(ImageAssetHorizontalAlignment).map(
                    (alignment) => (
                      <Select.Item key={alignment} value={alignment}>
                        {alignment}
                      </Select.Item>
                    )
                  )}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </Flex>
          <Flex direction="column" gap="4px">
            <Heading size="1">Subject Vertical Alignment</Heading>
            <Select.Root
              defaultValue={"auto"}
              onValueChange={(value) =>
                setSubjectVerticalAlignment(
                  value === "auto"
                    ? null
                    : (value as ImageAssetVerticalAlignment)
                )
              }
            >
              <Select.Trigger style={{ width: "100%" }} />
              <Select.Content>
                <Select.Group>
                  <Select.Item key="auto" value={"auto"}>
                    {"auto"}
                  </Select.Item>
                  {Object.values(ImageAssetVerticalAlignment).map(
                    (alignment) => (
                      <Select.Item key={alignment} value={alignment}>
                        {alignment}
                      </Select.Item>
                    )
                  )}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </Flex>
        </Flex>
        {operation === ImageAssetOperation.generate_hero && (
          <Flex direction="column" gap="4px">
            <Flex direction="row" align="center" justify="between">
              <Heading size="1">{"Background Prompt (optional)"}</Heading>
              <Button
                size="1"
                variant="surface"
                loading={isBackgroundPromptGenerating}
                onClick={() => {
                  if (assetID) {
                    generateBackgroundPromptMutation({
                      brand_id: activeBrandID,
                      image_asset_id: assetID,
                    });
                  }
                }}
              >
                <Sparkles size="16px" /> Generate prompt
              </Button>
            </Flex>
            <TextArea
              size="1"
              value={backgroundPrompt ?? ""}
              disabled={isBackgroundPromptGenerating}
              onChange={(e) => {
                setBackgroundPrompt(
                  e.target.value.length > 0 ? e.target.value : null
                );
              }}
              style={{ height: "120px" }}
              placeholder="Describe the background in detail. Leave empty to allow Tempo to generate one for you."
            />
          </Flex>
        )}
        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => setIsDialogOpen(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            disabled={isBackgroundPromptGenerating}
            loading={isGenerating}
            onClick={() => {
              if (assetID) {
                generateImageMutation({
                  brand_id: activeBrandID,
                  operation: operation,
                  orientation: orientation,
                  subject_horizontal_alignment: subjectHorizontalAlignment,
                  subject_vertical_alignment: subjectVerticalAlignment,
                  image_asset_id: assetID,
                  image_url: null,
                  commerce_platform_item_id: null,
                  prompt:
                    backgroundPrompt && backgroundPrompt.length > 0
                      ? backgroundPrompt
                      : null,
                  campaign_id: null,
                });
              }
            }}
          >
            Generate
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default InternGenerateImageAssetDialog;
