import CampaignDraftCard from "./CampaignDraftCard";
import CampaignDraftDummyCard from "./CampaignDraftDummyCard";
import useGetCampaigns from "@hooks/campaign/useGetCampaigns";
import { CampaignStatus } from "@openapi";
import { Flex, Grid, Spinner, Text } from "@radix-ui/themes";
import { Info } from "lucide-react";
import { useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { useMarkPopupDispatch } from "~/contexts/MarkPopupContext";

const Banner = styled(Flex)`
  background: white;
  box-shadow: 0px 2px 4px 0px #0000000f;
  border-radius: 12px;
  font-size: 18px;
`;
const CampaignDraftSection = () => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useGetCampaigns({
    status: CampaignStatus.draft,
    limit: 6,
  });
  const allCampaigns = useMemo(
    () => data?.pages?.flatMap((page) => page.campaigns) ?? [],
    [data]
  );
  const showZeroState = data && !allCampaigns.length;
  const popupDispatch = useMarkPopupDispatch();
  useEffect(() => {
    popupDispatch({
      type: "SET_MESSAGE",
      payload: undefined,
    });
  }, []);

  const zeroStateBanner = (
    <Banner align={"center"} justify={"start"} gap="4" p="4">
      <Info color="red" />
      <Text>
        You have no draft campaigns. Either generate a new campaign from the
        Campaign Recommendations, or create a new one.
      </Text>
    </Banner>
  );
  return (
    <Flex direction="column" overflow="auto" width="100%" gap="8">
      {showZeroState && zeroStateBanner}
      <InfiniteScroll
        style={{
          overflow: "visible",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: "24px",
        }}
        next={fetchNextPage}
        hasMore={hasNextPage ?? false}
        dataLength={allCampaigns.length ?? 0}
        loader={
          <Flex height="100%" width="100%" justify="center" align="center">
            <Spinner />
          </Flex>
        }
        scrollThreshold={"90%"}
      >
        <Grid columns={{ initial: "1", sm: "2", md: "3", lg: "3" }} gap="5">
          {allCampaigns.map((campaign) => (
            <CampaignDraftCard
              isLoading={isLoading}
              campaign={campaign}
              key={campaign.id}
            />
          ))}
          {showZeroState &&
            Array(3)
              .fill(0)
              .map((_, index) => <CampaignDraftDummyCard key={index} />)}
        </Grid>
      </InfiniteScroll>
    </Flex>
  );
};

export default CampaignDraftSection;
