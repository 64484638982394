import { useAddImageAssetToQuery } from "../media/useImageAssetsQuery";
import useGenerateImageAssetMutation, {
  GenerateImageAssetResponse,
} from "./useGenerateImageAssetMutation";
import useGetImageAssetGenerationStatus, {
  ImageAssetGenerationStatusResponse,
} from "./useGetImageAssetGenerationStatus";
import { useEffect, useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

function useGenerateImageAssetWithStatus({
  onScheduleSuccess,
  onScheduleError,
  onGenerationSuccess,
  onGenerationError,
  onComplete,
}: {
  onScheduleSuccess?: (data: GenerateImageAssetResponse) => void;
  onScheduleError?: (error: string) => void;
  onGenerationSuccess?: (data: ImageAssetGenerationStatusResponse) => void;
  onGenerationError?: (error: string) => void;
  onComplete?: (data: ImageAssetGenerationStatusResponse) => void;
}) {
  const activeBrandID = useActiveBrandID();
  const [generateImageSessionID, setGenerateImageSessionID] = useState<
    string | null
  >(null);

  const schedule = useGenerateImageAssetMutation({
    onSuccess: (data) => {
      if (data.job_session_id) {
        onScheduleSuccess?.(data);
        setGenerateImageSessionID(data.job_session_id);
      } else {
        onScheduleError?.("An error occurred while generating images.");
        setGenerateImageSessionID(null);
      }
    },
    onError: (error) => {
      setGenerateImageSessionID(null);
      if (error.response?.status === 403) {
        onScheduleError?.("You do not have permission to generate images.");
      } else {
        console.log(error);
        onScheduleError?.("An error occurred while generating images.");
      }
    },
  });

  // We optimistically add the image to queries defined in AssetSelectorDialogGenerateView
  // This adds it to the general Library query in the dialog, which we don't want. But
  // we might need some of the logic later on to be able to correctly apply to all necessary queries
  // const addImageAssetToQuery = useAddImageAssetToQuery();

  const generateImageStatus = useGetImageAssetGenerationStatus({
    brandId: activeBrandID,
    jobSessionId: generateImageSessionID,
  });

  useEffect(() => {
    if (!generateImageStatus) {
      return;
    }
    if (generateImageStatus.error) {
      onGenerationError?.(generateImageStatus.error);
    } else if (
      generateImageSessionID !== null &&
      !!generateImageStatus.image_asset
    ) {
      onGenerationSuccess?.(generateImageStatus);
    }
    if (generateImageStatus.is_finished) {
      setGenerateImageSessionID(null);
      onComplete?.(generateImageStatus);
    }
  }, [generateImageStatus, generateImageSessionID]);

  return {
    generateImageMutation: schedule.mutate,
    isGenerating: schedule.isPending || !!generateImageSessionID,
    generateImageSessionID,
    generateImageStatus,
  };
}

export default useGenerateImageAssetWithStatus;
