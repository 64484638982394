import {
  EmailContextProvider,
  useEmailState,
  useInitEmailState,
} from "../intern/email_editor/context/EmailEditorContext";
import FullscreenSpinner from "@components/core/FullscreenSpinner";
import { EmailEditorComponents } from "@components/editor/layout";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useEmailCreativeQuery from "~/hooks/emails/useEmailCreativeQuery";

const EmailEditorRoot = ({
  initialEmailId,
}: {
  initialEmailId: string | null;
}) => {
  const { activeEmailId } = useEmailState();
  const initEmailState = useInitEmailState();
  const { emailCreativeData } = useEmailCreativeQuery(
    activeEmailId ?? initialEmailId
  );

  useEffect(() => {
    if (emailCreativeData && initialEmailId) {
      const currentVariantIndex = emailCreativeData.variants.findIndex(
        (variant) => variant.id === emailCreativeData.selected_variant_id
      );
      initEmailState({
        ...emailCreativeData,
        activeEmailId: emailCreativeData.id,
        currentVariantIndex: currentVariantIndex,
        campaignId: emailCreativeData.campaign_id,
      });
    }
  }, [emailCreativeData, initialEmailId]);

  return (
    <EmailEditorComponents.Layout>
      <EmailEditorComponents.TopBar />
      <EmailEditorComponents.Preview />
      <EmailEditorComponents.Aside />
    </EmailEditorComponents.Layout>
  );
};

const EmailEditorContainer = () => {
  const { emailId } = useParams();

  if (!emailId) return <FullscreenSpinner />;

  return (
    <EmailContextProvider>
      <EmailEditorRoot initialEmailId={emailId} />
    </EmailContextProvider>
  );
};

export default EmailEditorContainer;
