import GenerateSectionWithPrompt from "./generate-section/GenerateSectionWithPrompt";
import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import BrandCustomSectionPropertiesModifier from "./modifiers/BrandCustomSectionPropertiesModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ImageModifier from "./modifiers/ImageModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { useState } from "react";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isHeroSection,
  isSalesEventHeroSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const HeroView = () => {
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections, id: emailCreativeId } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);

  if (!selectedSection) {
    return null;
  }

  if (
    !isHeroSection(selectedSection) &&
    !isNewSection(selectedSection) &&
    !isSalesEventHeroSection(selectedSection)
  ) {
    return null;
  }

  const image = selectedSection?.image ?? null;

  const imageMod = (
    <ImageModifier
      initialImageUrl={image?.image_url}
      onAssetChange={(asset) => {
        setSelectedAssetId((asset as ImageAssetSchema)?.id ?? null);
        updateSection({
          sectionId: selectedSection.id,
          field: "image.image_url",
          value: asset.url,
        });
      }}
    />
  );

  if (isNewSection(selectedSection)) {
    return (
      <GenerateSectionWithPrompt
        section={{
          ...selectedSection,
          type: EmailSectionType.hero,
        }}
        emailCreativeId={emailCreativeId}
        prepareGenerate={() => ({ asset_id: selectedAssetId })}
      >
        {imageMod}
      </GenerateSectionWithPrompt>
    );
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const ctaButton = selectedSection.cta_button;

  let customModifier = null;
  if (
    isSalesEventHeroSection(selectedSection) &&
    selectedSection.template.section_type ===
      EmailSectionType.hero_sales_event.toString()
  ) {
    customModifier = (
      <>
        <TextModifier
          textElement={selectedSection.discount_text}
          label="Discount"
          enabled={selectedSection.discount_text.enabled ?? false}
          fieldName="discount_text.text"
          onSwitchToggle={(enabled) => {
            updateSection({
              sectionId: selectedSection.id,
              field: "discount_text.enabled",
              value: enabled,
            });
          }}
        />
        <TextModifier
          textElement={selectedSection.promo_code}
          label="Promo Code"
          enabled={selectedSection.promo_code.enabled ?? false}
          fieldName="promo_code.text"
          onSwitchToggle={(enabled) => {
            updateSection({
              sectionId: selectedSection.id,
              field: "promo_code.enabled",
              value: enabled,
            });
          }}
        />
      </>
    );
  } else if (
    isHeroSection(selectedSection) ||
    selectedSection.template.section_type === EmailSectionType.hero.toString()
  ) {
    customModifier = (
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        enabled={selectedSection.subtitle.enabled ?? false}
        fieldName="subtitle.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value: enabled,
          });
        }}
      />
    );
  }

  return (
    <>
      <GeneratedVariantSelector />

      <BrandCustomSectionPropertiesModifier />

      {imageMod}

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value: enabled,
          });
        }}
      />

      {customModifier}

      <ButtonModifier
        fieldName="cta_button"
        buttonElement={ctaButton}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "cta_button.enabled",
            value: enabled,
          });
        }}
      />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default HeroView;
