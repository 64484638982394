import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type InternImageAssetVariantsResponse =
  operations["intern_api_get_image_asset_variants"]["responses"][200]["content"]["application/json"];

export const getImageAssetVariantsQueryKey = (imageAssetId?: string) => [
  "image-asset-variants",
  imageAssetId,
];

const useInternGetImageAssetVariants = ({
  imageAssetId,
}: {
  imageAssetId?: string;
}) => {
  const imageAssetQuery = useQuery<InternImageAssetVariantsResponse>({
    queryKey: getImageAssetVariantsQueryKey(imageAssetId),
    queryFn: async () => {
      const { data } = await axios.get(
        `/api/v1/intern/assets/image/${imageAssetId}/variants`,
        {}
      );
      return data;
    },
    retry: false,
    staleTime: 3600,
    enabled: !!imageAssetId,
  });
  return imageAssetQuery;
};

export default useInternGetImageAssetVariants;
