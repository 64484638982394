import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export type ClassifyCampaignImageAssetsStatusRequestData =
  operations["intern_api_get_classify_campaign_image_assets_status"]["requestBody"]["content"]["application/json"];

export type ClassifyCampaignImageAssetsStatusResponse =
  operations["intern_api_get_classify_campaign_image_assets_status"]["responses"][200]["content"]["application/json"] & {
    error?: string;
  };

export type useInternGetClassifyCampaignImageAssetsStatusProps = {
  brandId: string;
  jobSessionId: string | null;
};

const useInternGetClassifyCampaignImageAssetsStatus = ({
  brandId,
  jobSessionId,
}: useInternGetClassifyCampaignImageAssetsStatusProps) => {
  const { data } = useQuery<ClassifyCampaignImageAssetsStatusResponse>({
    queryKey: [
      "intern-classify-campaign-image-assets-status",
      brandId,
      jobSessionId,
    ],
    queryFn: async (): Promise<ClassifyCampaignImageAssetsStatusResponse> => {
      const requestBody: ClassifyCampaignImageAssetsStatusRequestData = {
        brand_id: brandId,
        job_session_id: jobSessionId!,
      };
      const response = await axios
        .post(
          "/api/v1/intern/assets/images/classify-campaign-status",
          requestBody,
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          }
        )
        .catch((error) => {
          console.log(
            "Error fetching classifying campaign image assets job status",
            error
          );
          return {
            data: {
              is_finished: true,
              error: "Failed to fetch job status",
            },
          };
        });
      return response.data;
    },
    enabled: jobSessionId !== null,
    refetchInterval: 3000,
    retry: false,
    staleTime: Infinity,
  });
  return data;
};

export default useInternGetClassifyCampaignImageAssetsStatus;
