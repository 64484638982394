import { CampaignStatus, operations } from "@openapi";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useMemo } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type CampaignsApiResponse =
  operations["campaign_api_my_campaigns"]["responses"][200]["content"]["application/json"];
type CampaignsApiParams =
  operations["campaign_api_my_campaigns"]["parameters"]["query"];
type CampaignsApiEmailMetricsResponse =
  operations["cdp_api_klaviyo_metrics"]["responses"][200]["content"]["application/json"];
type CampaignsApiAdMetricsResponse =
  operations["meta_api_get_ad_analytics"]["responses"][200]["content"]["application/json"];

const useGetCampaignList = ({
  page = 0,
  limit = 15,
  status,
}: {
  page?: number;
  limit?: number;
  status?: CampaignStatus | null;
}) => {
  const activeBrandID = useActiveBrandID();
  const campaignsQuery = useInfiniteQuery({
    queryKey: ["campaigns-list", activeBrandID, status],
    queryFn: async ({ pageParam }): Promise<CampaignsApiResponse> => {
      const { data } = await axios.get("/api/v1/campaign/my-campaigns", {
        params: {
          brand_id: activeBrandID,
          offset: pageParam * limit,
          limit: limit,
          status: status,
        },
      });
      return data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.has_more ? lastPageParam + 1 : undefined,
    enabled: !!activeBrandID,
  });

  const emailsMetricsQuery = useQuery({
    queryKey: ["email-metrics", activeBrandID],
    queryFn: async (): Promise<CampaignsApiEmailMetricsResponse> => {
      const { data } = await axios.get("/api/v1/cdp/klaviyo/metrics", {
        params: {
          brand_id: activeBrandID,
        },
      });
      return data;
    },
    enabled: !!activeBrandID,
  });

  const adIds = useMemo(
    () =>
      campaignsQuery.data?.pages.flatMap((page) =>
        page.campaigns.flatMap((campaign) =>
          campaign.ad_assets.map((ad) => ad.id)
        )
      ),
    [campaignsQuery.data]
  );

  const adMetricsQuery = useQuery({
    queryKey: ["ad-metrics", activeBrandID, adIds],
    queryFn: async (): Promise<CampaignsApiAdMetricsResponse> => {
      const { data } = await axios.post(
        "/api/v1/meta/ad/analytics",
        {
          ad_ids: adIds,
          brand_id: activeBrandID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    enabled: !!activeBrandID && !!adIds?.length,
  });

  return { campaignsQuery, emailsMetricsQuery, adMetricsQuery };
};

export default useGetCampaignList;
