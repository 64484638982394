import BrandAsset from "./BrandAsset";
import BrandAssetDeleteDialog from "./BrandAssetDeleteDialog";
import classes from "./BrandAssets.module.css";
import { ImageAssetSchema } from "./BrandImageAsset";
import { ImageAssetCategory } from "@openapi";
import { Box, Button, Flex, Heading } from "@radix-ui/themes";
import { forwardRef, useEffect, useRef, useState } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import ImageViewer from "react-simple-image-viewer";
import scrollIntoView from "scroll-into-view-if-needed";
import { Trash2Icon } from "~/assets/icons";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import { DROPZONE_IMAGE_TYPES } from "~/components/core/dropzone/Dropzone";
import DropzoneContainer from "~/components/core/dropzone/DropzoneContainer";
import { useBrandStylingDispatch } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useUploadImageAssets, {
  getFormImageAssetCategory,
} from "~/hooks/style-library/useUploadImageAssets";

interface BrandAssetsProps {
  assets: ImageAssetSchema[];
  canDelete?: boolean;
  multiple?: boolean;
  category: ImageAssetSchema["category"];
}

const BrandAssets = forwardRef<DropzoneRef, BrandAssetsProps>(
  ({ assets, canDelete = false, multiple, category }, ref) => {
    const [deletingAsset, setDeletingAsset] = useState<ImageAssetSchema | null>(
      null
    );
    const [selectedAssetIndex, setSelectedAssetIndex] = useState<number | null>(
      null
    );
    const [uploadError, setUploadError] = useState<string | null>(null);
    const activeBrandID = useActiveBrandID();
    const brandStylingDispatch = useBrandStylingDispatch();
    const uploadRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!uploadRef.current) return;
      scrollIntoView(uploadRef.current, {
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, [uploadRef.current]);

    const { mutate: uploadImageAssets, isPending: isUploading } =
      useUploadImageAssets({
        onSuccess: (data) => {
          brandStylingDispatch({
            type: "ADD_IMAGE_ASSETS",
            payload: data.new_assets,
          });
        },
        onError: (error) => {
          if (error.response?.status === 403) {
            setUploadError("You do not have permission to upload images.");
          } else {
            setUploadError("An error occurred while uploading images.");
          }
        },
      });

    const handleUpload = (acceptedFiles: File[]) => {
      setUploadError(null);
      uploadImageAssets({
        brand_id: activeBrandID,
        category: getFormImageAssetCategory(category),
        images: acceptedFiles as unknown as string[],
      });
    };

    return (
      <>
        {uploadError && (
          <Heading size="1" color="red" align={"center"}>
            {uploadError}
          </Heading>
        )}
        <Dropzone
          ref={ref}
          accept={DROPZONE_IMAGE_TYPES}
          multiple={multiple ?? true}
          onDrop={handleUpload}
          disabled={isUploading}
        >
          {(dropzoneProps) => (
            <DropzoneContainer
              {...dropzoneProps}
              variant="button"
              label="Drag and drop background image(s) here or"
              buttonText="Upload image(s)"
              color="var(--text-secondary)"
              style={{
                display: !assets.length ? "block" : "none",
              }}
            />
          )}
        </Dropzone>
        <Box
          my={"2"}
          style={{
            overflow: "scroll",
            display: !!assets.length ? "block" : "none",
          }}
        >
          <div className={classes.container}>
            {assets.map((asset, index) => (
              <BrandAsset
                key={asset.id}
                asset={asset}
                originalFilename={asset.original_filename}
                overlay={
                  <div
                    className={classes.overlay}
                    onClick={() => setSelectedAssetIndex(index)}
                  >
                    {canDelete && (
                      <Button
                        variant="surface"
                        size="1"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setDeletingAsset(asset);
                        }}
                      >
                        <Trash2Icon />
                      </Button>
                    )}
                  </div>
                }
              />
            ))}

            {isUploading && (
              <Flex
                justify={"center"}
                className={
                  category === ImageAssetCategory.logo
                    ? classes.logoLoadContainer
                    : classes.loadContainer
                }
                ref={uploadRef}
              >
                <LoadingDots />
              </Flex>
            )}
          </div>
          {canDelete && deletingAsset && (
            <BrandAssetDeleteDialog
              title="Delete Background Image"
              asset={deletingAsset}
              isOpen={deletingAsset !== null}
              setIsOpen={(isOpen) => {
                if (isOpen) return;
                setDeletingAsset(null);
              }}
              onAssetDeleted={() => setDeletingAsset(null)}
            />
          )}
          {selectedAssetIndex !== null && (
            <Box style={{ zIndex: 101, position: "fixed" }}>
              <ImageViewer
                src={assets.map((a) => a.url)}
                currentIndex={selectedAssetIndex}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={() => setSelectedAssetIndex(null)}
                backgroundStyle={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
              />
            </Box>
          )}
        </Box>
      </>
    );
  }
);

export default BrandAssets;
