import BrandImageAsset, { ImageAssetSchema } from "./BrandImageAsset";
import { ImageAssetSource } from "@openapi";
import { Tooltip, Text, Box, Flex } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";
import { SparklesIcon } from "~/assets/icons";
import patternSrc from "~/assets/pattern.png";
import { AssetSelectorItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";

const AssetContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  &:hover .overlay {
    display: block;
  }
`;

const FilenameText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 100px;
  text-align: center;
  color: var(--text-tertiary);
`;

const Badge = styled(Flex)`
  align-items: center;
  background-color: #fff;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
  box-shadow: 0px 2px 8px 0px #0000000a;
  border-radius: 8px;
  padding: 4px;
`;

const BrandAsset = ({
  asset,
  originalFilename,
  overlay,
  onClick,
  showGeneratedBadge = false,
  height,
  width,
}: {
  asset: AssetSelectorItem;
  originalFilename?: string;
  onClick?: () => void;
  overlay?: React.ReactNode;
  showGeneratedBadge?: boolean;
  height?: number;
  width?: number;
}) => {
  const generatedBadge =
    showGeneratedBadge &&
    (asset as ImageAssetSchema)?.source === ImageAssetSource.gen_ai ? (
      <Badge>
        <SparklesIcon size="20px" stroke="#8C3FFF" fill="#8C3FFF" />
      </Badge>
    ) : null;
  return (
    <Tooltip
      content={originalFilename}
      side={"bottom"}
      hidden={!originalFilename}
    >
      <AssetContainer onClick={onClick}>
        <Box
          position={"relative"}
          style={{
            cursor: "pointer",
            overflow: "hidden",
            borderRadius: "var(--radius-3)",
            background: `url(${patternSrc})`,
            backgroundSize: "100% 100%",
          }}
        >
          <BrandImageAsset url={asset.url} width={width} height={height} />
          {generatedBadge}
          {overlay}
        </Box>

        {!!originalFilename && (
          <FilenameText size={"3"}>{originalFilename}</FilenameText>
        )}
      </AssetContainer>
    </Tooltip>
  );
};

export default BrandAsset;
