import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import {
  useCampaignWizardDispatch,
  useCampaignWizardNextStep,
} from "../../../../contexts/CampaignWizardContext";
import { useEffect, useMemo, useState } from "react";

const useCampaignDetailsProps = () => {
  const dispatch = useCampaignWizardDispatch();
  const nextStep = useCampaignWizardNextStep();
  const [description, setDescription] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children" | "step">>(
    () => ({
      badgeTitle: "Campaign Details",
      nextButtonProps: {
        text: isEditing ? "Reply" : "Continue",
        onClick: () => {
          if (isEditing) {
            setIsEditing(false);
          } else {
            nextStep();
          }
        },
        disabled: description.trim().length < 30,
      },
    }),
    [isEditing, description, nextStep]
  );

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: { description_input: description },
    });
  }, [description, dispatch]);

  const inputProps = useMemo(
    () => ({
      value: description,
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setDescription(e.target.value),
      isEditing,
      setIsEditing,
    }),
    [description, isEditing]
  );

  return { cardProps, inputProps };
};

export default useCampaignDetailsProps;
