import { CampaignRecommendation } from "../../hooks/recommendations/useGetCampaignRecommendations";
import CampaignProductsPreview from "../common/CampaignProductsPreview";
import { ItemSetType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ArrowRightIcon,
  DiscountIcon,
  HeartSparksIcon,
  MailIcon,
  MetaIcon,
  PackageBoxIcon,
  PackageOpenIcon,
  ShoppingBagClickIcon,
  StarSparksIcon,
  StorageBoxesIcon,
  TimeBackIcon,
} from "~/assets/icons";
import DraperPill from "~/components/core/DraperPill";
import DraperText, { DraperHeading } from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { timeAgoDescription } from "~/utils/timeUtils";
import { RecommendedCampaignChannelCountDefaults } from "~/views/campaign/wizard/hooks/useCampaignChannelProps";

const StyledPill = styled(DraperPill)`
  position: relative;
  top: 4px;
`;

export const campaignRecommendationTypeContent: {
  [key in ItemSetType]: {
    icon: typeof PackageOpenIcon;
    title: string;
    description?: React.ReactNode;
  };
} = {
  [ItemSetType.new_products]: {
    icon: StarSparksIcon,
    title: "New Products",
    description: (
      <>
        New added products are preforming well, selling <b>1.7x</b> better than
        average products.
      </>
    ),
  },
  [ItemSetType.back_in_stock]: {
    icon: StorageBoxesIcon,
    title: "Back in Stock",
  },
  [ItemSetType.best_sellers]: {
    icon: ShoppingBagClickIcon,
    title: "Best Sellers",
  },
  [ItemSetType.discounted_products]: {
    icon: DiscountIcon,
    title: "Discounted Products",
  },
  [ItemSetType.hero_product]: {
    icon: PackageOpenIcon,
    title: "Hero Product",
  },
  [ItemSetType.new_collection]: {
    icon: HeartSparksIcon,
    title: "New Collection",
  },
  [ItemSetType.new_hero_product]: {
    icon: PackageOpenIcon,
    title: "New Hero Product",
  },
  [ItemSetType.purchased_together]: {
    icon: PackageBoxIcon,
    title: "Purchased Together",
  },
  [ItemSetType.slower_moving_goods]: {
    icon: StorageBoxesIcon,
    title: "Slow Moving Goods",
    description: (
      <>
        Products are not preforming well, selling <b>1.7x</b> below the average
        products.
      </>
    ),
  },
  [ItemSetType.slowest_moving_goods]: {
    icon: StorageBoxesIcon,
    title: "Slowest Moving Goods",
  },
  [ItemSetType.trending_products]: {
    icon: ShoppingBagClickIcon,
    title: "Trending Products",
  },
};

const Card = styled(Flex)`
  padding: 20px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const RecommenderCard = ({
  recommendation,
}: {
  recommendation: CampaignRecommendation;
}) => {
  const productsData = recommendation.products ?? [];

  const navigate = useNavigate();

  const recommendationConfig =
    campaignRecommendationTypeContent[recommendation.item_set_type];
  const IconComponent = recommendationConfig.icon;

  const channels =
    RecommendedCampaignChannelCountDefaults[recommendation.item_set_type];

  return (
    <Card
      direction="row"
      position="relative"
      gap="5"
      overflow="hidden"
      minWidth="780px"
    >
      <CampaignProductsPreview productsData={productsData} />

      <Flex direction="column" gap="5" overflow="hidden" width="100%">
        <Flex direction="column" gap="3">
          <DraperHeading size="6">{recommendation.title}</DraperHeading>
          <DraperText
            size="4"
            clamp={2}
            wordBreak="break-word"
            weight="medium"
            variant="secondary"
          >
            {recommendation.description}
          </DraperText>
        </Flex>
        <DraperText
          size="4"
          variant="secondary"
          wordBreak="break-word"
          lineHeight="26px"
        >
          <StyledPill
            flexShrink="0"
            backgroundColor="var(--black-4, #0000000A)"
            mr="10px"
            height="26px"
            align="baseline"
            icon={
              <IconComponent
                size={20}
                strokeWidth={1.25}
                color="var(--primary-vibrant-orange)"
              />
            }
          >
            {recommendationConfig.title}
          </StyledPill>

          <span
            dangerouslySetInnerHTML={{
              __html: recommendation.reasoning_description ?? "",
            }}
          />
        </DraperText>
        <Flex direction="row" gap="5" justify="between" align="center">
          <AppButton
            onClick={() => navigate(`/campaigns/wizard/${recommendation.id}`)}
            mt="auto"
            variant="soft"
          >
            Open Campaign
            <ArrowRightIcon />
          </AppButton>
          <Flex
            gap="4"
            align="center"
            style={{
              color: "var(--primary-deep-purple)",
            }}
          >
            {channels["Meta Ads"] > 0 && <MetaIcon size={20} />}
            {channels.Email > 0 && <MailIcon size={20} />}
            <Flex gap="2" align="center" px="12px">
              <TimeBackIcon size={15} color="var(--icon-primary)" />
              <DraperText size="2" variant="tertiary">
                {timeAgoDescription(recommendation.created_at)}
              </DraperText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default RecommenderCard;
