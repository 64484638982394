import useGetProductRelatedImages from "../shopify/useGetProductRelatedImages";
import { AssetSelectorCommercePlatformItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useShopifyCollectionImagesQuery from "~/hooks/shopify/useShopifyCollectionImagesQuery";
import useShopifyProductsQuery from "~/hooks/shopify/useShopifyProductsQuery";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const useGetEmailRelatedImages = ({
  productId,
  collectionId,
}: {
  productId?: string | null;
  collectionId?: string | null;
}) => {
  const { related_commerce_item_ids } = useEmailState();
  const activeBrandID = useActiveBrandID();

  // product specific product images
  const {
    images: productRelatedAssets,
    isLoading: isProductQueryLoading,
    error: productError,
  } = useGetProductRelatedImages(productId);

  //collection specific product images
  const {
    data: collectionData,
    isLoading: isCollectionQueryLoading,
    error: collectionError,
  } = useShopifyCollectionImagesQuery({
    collectionId: collectionId,
    limit: 6,
  });
  const collectionRelatedAssets: AssetSelectorCommercePlatformItem[] =
    collectionData?.images?.map((url) => ({
      url,
      commerce_platform_item_id: collectionData.id,
    })) ?? [];

  //email general product images
  const {
    productsData: emailRelatedProductsData,
    isLoading: isDefaultImagesQueryLoading,
    error: emailProductsError,
  } = useShopifyProductsQuery(activeBrandID, related_commerce_item_ids);
  const defaultRelatedAssets: AssetSelectorCommercePlatformItem[] =
    emailRelatedProductsData
      ?.filter((product) => product.image_url)
      .map((product) => ({
        url: product.image_url as string,
        commerce_platform_item_id: product.product_id,
      })) ?? [];

  return {
    images: productId
      ? productRelatedAssets
      : collectionId
      ? collectionRelatedAssets
      : defaultRelatedAssets,
    isLoading:
      isProductQueryLoading ||
      isCollectionQueryLoading ||
      isDefaultImagesQueryLoading,
    error: productError || collectionError || emailProductsError,
  };
};

export default useGetEmailRelatedImages;
