import { Flex, Heading, Text } from "@radix-ui/themes";
import { ChevronUp, ChevronRight } from "lucide-react";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import SidePanelComponents from "~/components/core/editor/sidepane";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useEmailSectionBrandUpdateMutation from "~/hooks/emails/useEmailSectionBrandUpdateMutation";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isNewSection } from "~/utils/emails/useSectionTypeCheck";

const CustomCard = styled(Flex)<{ $backgroundColor?: string }>`
  border-radius: 20px;
  border: 1px solid var(--border-primary);
  box-shadow: 0px 2px 8px 0px #0000000a;
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
`;

const ClickableHeader = styled(Flex)`
  cursor: pointer;
  border-radius: 12px;
`;

const BrandCustomSectionPropertiesModifier = () => {
  const activeBrandID = useActiveBrandID();
  const [expanded, setExpanded] = useState(true);

  const { selectedSectionId, sections } = useEmailState();
  const section = sections.find((s) => s.id === selectedSectionId);

  const onClose = useCallback(() => {
    setExpanded(false);
  }, []);

  const { updateEmailSectionBrand, isLoading } =
    useEmailSectionBrandUpdateMutation({
      brandID: activeBrandID,
      onSuccess: (data) => {
        toast.success("Properties saved");
        onClose();
      },
      onError: (error) => {
        toast.error("Failed to save properties");
      },
    });

  const onSaveProperties = useCallback(() => {
    if (!section) return;
    if (isNewSection(section)) return;
    updateEmailSectionBrand({
      brand_id: activeBrandID,
      section: section,
    });
  }, [activeBrandID, section, updateEmailSectionBrand]);

  return (
    <CustomCard $backgroundColor="#FCFBFA">
      <Flex direction="column" gap="4" width="100%">
        <Flex
          direction="row"
          justify="between"
          minHeight="20px"
          align="center"
          px="3"
          pt="3"
        >
          <ClickableHeader
            onClick={() => setExpanded(!expanded)}
            justify="between"
            align="center"
            width="100%"
            gap="2"
          >
            <Heading size="2">Save Properties</Heading>
            <Flex align="center">
              {expanded ? <ChevronUp size={20} /> : <ChevronRight size={20} />}
            </Flex>
          </ClickableHeader>
        </Flex>
        {expanded ? (
          <Flex direction="column" px="3" pb="3" gap="2" width="100%">
            <Text size="2">
              Would you like to set the current customizations (font, colors,
              size) for future emails?
            </Text>

            <Flex gap="2">
              <SidePanelComponents.Button onClick={onClose}>
                Close
              </SidePanelComponents.Button>
              <SidePanelComponents.Button
                style={{ flex: 1 }}
                onClick={onSaveProperties}
                loading={isLoading}
              >
                Save Properties
              </SidePanelComponents.Button>
            </Flex>
          </Flex>
        ) : (
          <div />
        )}
      </Flex>
    </CustomCard>
  );
};

export default BrandCustomSectionPropertiesModifier;
