import { TabNav } from "@radix-ui/themes";
import { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useDynamicHeader } from "~/contexts/DynamicHeaderContext";

const DynamicHeaderTabNavRoot = styled(TabNav.Root)`
  box-shadow: none;
`;

const DynamicHeaderTabNavLink = styled(TabNav.Link)`
  font-size: 18px;
  margin: 10px;
`;

export default function AssetsRoute() {
  const { setDynamicHeader } = useDynamicHeader();

  const location = useLocation();

  useEffect(() => {
    setDynamicHeader(
      <DynamicHeaderTabNavRoot>
        <DynamicHeaderTabNavLink
          asChild
          active={location.pathname.includes("/intern/assets")}
        >
          <Link to={"/intern/assets/images"}>Images</Link>
        </DynamicHeaderTabNavLink>
      </DynamicHeaderTabNavRoot>
    );
    return () => {
      setDynamicHeader(<></>);
    };
  }, [setDynamicHeader, location]);

  return (
    <div style={{ height: "100%" }}>
      <Outlet />
    </div>
  );
}
