import InternAdCreativeComponent from "../../components/ads/intern/InternAdCreativeComponent";
import InternAdCreativesComponent from "../../components/ads/intern/InternAdCreativesComponent";
import InternAdMediaTemplateComponent from "../../components/ads/intern/InternAdMediaTemplateComponent";
import InternAdMediaTemplateVariantComponent from "../../components/ads/intern/InternAdMediaTemplateVariantComponent";
import InternAdMediaTemplatesComponent from "../../components/ads/intern/InternAdMediaTemplatesComponent";
import InternCampaignsComponent from "../../components/campaign/intern/InternCampaignsComponent";
import PrivateRoute from "../../components/core/auth/PrivateRoute";
import InternEmailTemplatesComponent from "../../components/emails/intern/InternEmailTemplatesComponent";
import AdsRoute from "../intern/ads";
import AssetsRoute from "../intern/assets";
import BrandRoute from "../intern/brand";
import InternCampaignsRoute from "../intern/campaigns";
import EmailsRoute from "../intern/emails";
import RecommenderRoute from "../intern/recommender";
import InternRoute from "../intern/root";
import StylesRoute from "../intern/styles";
import { Theme } from "@radix-ui/themes";
import { RouteObject, Navigate } from "react-router-dom";
import InternAssetComponent from "~/components/assets/intern/InternImageAssetComponent";
import InternImageAssetsComponent from "~/components/assets/intern/InternImageAssetsComponent";
import InternBrandDashboardComponent from "~/components/brand/intern/dashboard/InternBrandDashboardComponent";
import InternBrandEmailTab from "~/components/intern/brand/InternBrandEmailTab";
import InternRootContainer from "~/views/intern/InternRootContainer";
import InternCampaignDetailContainer from "~/views/intern/campaigns/InternCampaignDetailContainer";
import InternCampaignEmailContainer from "~/views/intern/campaigns/InternCampaignEmailContainer";

export default function getInternRoutes(): RouteObject[] {
  return [
    {
      path: "/intern",
      element: (
        <Theme>
          <PrivateRoute isInternal>
            <InternRoute />
          </PrivateRoute>
        </Theme>
      ),
      children: [
        {
          path: "",
          element: <InternRootContainer />,
        },
        {
          path: "brand",
          element: <BrandRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="dashboard" />,
            },
            {
              path: "dashboard",
              element: <InternBrandDashboardComponent />,
            },
            {
              path: "emails",
              element: <InternBrandEmailTab />,
            },
          ],
        },
        {
          path: "recommender",
          element: <RecommenderRoute />,
        },
        {
          path: "styles",
          element: <StylesRoute />,
        },
        {
          path: "assets",
          element: <AssetsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="images" />,
            },
            {
              path: "images",
              element: <InternImageAssetsComponent />,
            },
            {
              path: "images/:id",
              element: <InternAssetComponent />,
            },
          ],
        },
        {
          path: "ads",
          element: <AdsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="templates" />,
            },
            {
              path: "templates",
              element: <InternAdMediaTemplatesComponent />,
            },
            {
              path: "template/:id",
              element: <InternAdMediaTemplateComponent />,
            },
            {
              path: "template/:templateId/variant/:variantId",
              element: <InternAdMediaTemplateVariantComponent />,
            },
            {
              path: "creatives",
              element: <InternAdCreativesComponent />,
            },
            {
              path: "creative/:id",
              element: <InternAdCreativeComponent />,
            },
          ],
        },
        {
          path: "emails",
          element: <EmailsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="templates" />,
            },
            {
              path: "templates",
              element: <InternEmailTemplatesComponent />,
            },
          ],
        },
        {
          path: "campaign",
          element: <InternCampaignsRoute />,
          children: [
            {
              path: "",
              element: <InternCampaignsComponent />,
            },
            {
              path: ":id",
              element: <InternCampaignDetailContainer />,
              children: [
                {
                  path: "email/:emailId",
                  element: <InternCampaignEmailContainer />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
