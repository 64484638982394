import { Card, Flex, IconButton, Separator, Text } from "@radix-ui/themes";
import { useCallback } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { PencilIcon } from "~/assets/icons";
import AudiencePill from "~/components/assets/selector/AudiencePill";
import SidePanelComponents from "~/components/core/editor/sidepane";
import TextAreaWithLabel from "~/components/core/inputs/TextAreaWithLabel";
import TextFieldWithLabel from "~/components/core/inputs/TextFieldWithLabel";
import AudienceDialog from "~/components/dialogs/AudienceDialog";
import useEmailRegenerateMutation from "~/hooks/emails/useEmailRegenerateMutation";
import useGenerateEmailVariantWithStatus from "~/hooks/emails/useGenerateEmailVariantWithStatus";
import {
  useAddVariant,
  useEmailDispatch,
  useEmailState,
  useSetAudiences,
  useSetIsEmailDirty,
  useUpdateEmailField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const SectionSeparator = styled(Separator)`
  width: 100%;
`;

const DescriptionText = styled(Text)`
  color: var(--text-tertiary);
`;

const GenerateVariantView = () => {
  const {
    name,
    subtitle,
    audiences,
    excluded_audiences,
    id: emailCreativeId,
    variants,
    currentVariantIndex,
  } = useEmailState();
  const emailDispatch = useEmailDispatch();
  const updateEmailField = useUpdateEmailField();
  const setAudiences = useSetAudiences();
  const addVariant = useAddVariant();
  const setIsDirty = useSetIsEmailDirty();
  const { regenerateEmail, isGenerating: isLoading } =
    useGenerateEmailVariantWithStatus({
      onScheduleError(error) {
        toast.error(error);
      },
      onGenerationSuccess(variant) {
        addVariant(variant);
        setIsDirty(false);
      },
      onGenerationError(error) {
        toast.error(error);
      },
    });

  const onGenerateEmail = useCallback(() => {
    regenerateEmail({ email_creative_id: emailCreativeId });
    toast.info(
      <span>
        A new email version is being generated.
        <br />
        This may take up to a few minutes.
      </span>
    );
  }, [regenerateEmail, emailCreativeId]);

  const handleSelect = useCallback(
    (index: number) => {
      emailDispatch({
        type: "SET_CURRENT_VARIANT_INDEX",
        payload: index,
      });
    },
    [addVariant, currentVariantIndex, variants, setIsDirty]
  );

  return (
    <Flex direction="column" gap="24px">
      <SidePanelComponents.MarkMessage>
        Select any block you want modify, or generate a new option.
      </SidePanelComponents.MarkMessage>
      <SidePanelComponents.LayoutVariants
        isGenerating={isLoading}
        onClickGenerate={onGenerateEmail}
        selectedIndex={currentVariantIndex}
        onSelect={handleSelect}
        variants={variants.map((variant) => ({
          id: variant.id,
          preview: variant.preview_image ?? undefined,
        }))}
      />
      <SectionSeparator />
      <TextFieldWithLabel
        label="Email Subject"
        value={name}
        onChange={(e) =>
          updateEmailField({ field: "name", value: e.target.value })
        }
      />
      <Flex gap="8px" direction="column">
        <TextAreaWithLabel
          labelColor="var(--text-tertiary)"
          label="Preheader Text"
          value={subtitle}
          onChange={(e) =>
            updateEmailField({ field: "subtitle", value: e.target.value })
          }
        />
        <DescriptionText size="2" weight="regular">
          A preheader is the short summary text that follows the subject line
          when viewing an email from the inbox.
        </DescriptionText>
      </Flex>

      <Card style={{ padding: "16px", position: "relative" }}>
        <AudienceDialog
          initialAudiences={audiences}
          initialExclusions={excluded_audiences}
          trigger={
            <IconButton
              style={{
                position: "absolute",
                top: "16px",
                right: "16px",
              }}
              color="gray"
              variant="ghost"
            >
              <PencilIcon size={20} />
            </IconButton>
          }
          onConfirm={(audiences, exclusions) => {
            setAudiences(audiences, exclusions);
          }}
        />
        <Flex direction="column" gap="8px">
          <Text size="2" weight="medium">
            Target Audience
          </Text>
          {audiences.length > 0 ? (
            <Flex gap="8px" wrap="wrap">
              {audiences.map((audience) => (
                <AudiencePill key={audience.audience_id} audience={audience} />
              ))}
            </Flex>
          ) : (
            <Flex gap="8px" wrap="wrap">
              <AudiencePill />
            </Flex>
          )}
          {!!excluded_audiences.length && (
            <>
              <Text size="2" weight="medium">
                Excluded Audience
              </Text>
              <Flex gap="8px" wrap="wrap">
                {excluded_audiences.map((audience) => (
                  <AudiencePill
                    key={audience.audience_id}
                    audience={audience}
                    variant="exclude"
                  />
                ))}
              </Flex>
            </>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default GenerateVariantView;
