import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

export type ClassifyCampaignImageAssetsRequestData =
  operations["intern_api_classify_campaign_image_assets"]["requestBody"]["content"]["application/json"];

export type ClassifyCampaignImageAssetsResponse =
  operations["intern_api_classify_campaign_image_assets"]["responses"][200]["content"]["application/json"];

const useInternClassifyCampaignImageAssets = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: ClassifyCampaignImageAssetsResponse) => void;
  onError?: (error: any) => void;
}) => {
  const classifyCampaignImageAssetsMutation = useMutation<
    ClassifyCampaignImageAssetsResponse,
    AxiosError,
    ClassifyCampaignImageAssetsRequestData
  >({
    mutationFn: async (
      params: ClassifyCampaignImageAssetsRequestData
    ): Promise<ClassifyCampaignImageAssetsResponse> => {
      const { data } = await axios.post(
        "/api/v1/intern/assets/images/classify-campaign",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data.job_session_id === null) {
        alert("There was an issue with your request. Try again later.");
        return;
      }
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
  });

  return { ...classifyCampaignImageAssetsMutation };
};

export default useInternClassifyCampaignImageAssets;
