import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

export type GenerateImageAssetRequestData =
  operations["media_api_generate_image_asset"]["requestBody"]["content"]["application/json"];

export type GenerateImageAssetResponse =
  operations["media_api_generate_image_asset"]["responses"][200]["content"]["application/json"];

const useGenerateImageAssetMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: GenerateImageAssetResponse) => void;
  onError?: (error: any) => void;
}) => {
  const generateImageMutation = useMutation<
    GenerateImageAssetResponse,
    AxiosError,
    GenerateImageAssetRequestData
  >({
    mutationFn: async (
      params: GenerateImageAssetRequestData
    ): Promise<GenerateImageAssetResponse> => {
      const { data } = await axios.post(
        "/api/v1/media/assets/images/generate",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (!data.success || data.job_session_id === null) {
        alert("There was an issue with your request. Try again later.");
        return;
      }
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
  });

  return { ...generateImageMutation };
};

export default useGenerateImageAssetMutation;
