import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

type ImageAssetBackgroundPromptResponse =
  operations["media_api_generate_image_asset_background_prompt"]["responses"][200]["content"]["application/json"];

type ImageAssetBackgroundPromptRequestData =
  operations["media_api_generate_image_asset_background_prompt"]["requestBody"]["content"]["application/json"];

const useImageAssetBackgroundPromptQuery = (
  params: ImageAssetBackgroundPromptRequestData
) => {
  const { data, isLoading, isSuccess, error } =
    useQuery<ImageAssetBackgroundPromptResponse>({
      queryKey: ["image-asset-background-prompt", params],
      queryFn: async (): Promise<ImageAssetBackgroundPromptResponse> => {
        const { data } = await axios.post(
          "/api/v1/media/assets/images/generate-background-prompt",
          params,
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          }
        );
        return data;
      },
      enabled: !!params,
    });

  return {
    backgroundPrompt: data?.prompt ?? null,
    isLoading,
    isSuccess,
    error,
  };
};

export default useImageAssetBackgroundPromptQuery;
