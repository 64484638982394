import InternGenerateImageAssetDialog from "../assets/intern/InternGenerateImageAssetDialog";
import { ImageAssetSchema } from "../style-library/assets/BrandImageAsset";
import { operations } from "@openapi";
import { Flex, Button } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { Cog, RefreshCcw, Sparkles } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useInternReclassifyImageAsset from "~/hooks/intern/useInternReclassifyImageAsset";

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  pointer-events: none;
  z-index: 1;
`;

const CardWrapper = styled.div`
  position: relative;
  height: 100%;
  min-width: 250px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

const SourceLabel = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  z-index: 2;

  & img {
    border-radius: 4px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    object-fit: cover;
  }
`;

const CategoryLabel = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  z-index: 2;
`;

const VariantLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  z-index: 2;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
`;

const Image = styled.img`
  min-width: 100%;
  aspect-ratio: 1;
  border-radius: 4px;
  object-fit: contain;
  display: block;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%);
  background-size: 20px 20px; /* Adjust the size as necessary */
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
`;

const CardTitle = styled.p`
  margin: 1px 0;
  font-size: 0.7rem;
  font-weight: bold;
  color: #444;
`;

const CardSubtitle = styled.p`
  margin: 2px 0;
  font-size: 0.6rem;
  color: #666;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:hover {
    transform: translateY(-5px);
  }
`;

type ReclassifyImageAssetResponse =
  operations["intern_api_reclassify_image_asset"]["responses"][200]["content"]["application/json"];

const InternImageAssetCard = ({
  asset,
  queryKey,
}: {
  asset: ImageAssetSchema;
  queryKey?: any[];
}) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [mutatingCardId, setMutatingCardId] = useState<string | null>(null);

  const [isGenerateImageDialogOpen, setIsGenerateImageDialogOpen] =
    useState<boolean>(false);

  const [selectedAssetID, setSelectedAssetID] = useState<string | null>(null);

  const { mutate: reclassify } = useInternReclassifyImageAsset(
    asset.id,
    queryKey,
    {
      onSuccess: (response: ReclassifyImageAssetResponse) => {
        setMutatingCardId(null);
        if (response.image_asset.category !== response.new_category) {
          alert(
            `Image classified to ${response.image_asset.category} and created new asset ${response.new_category}`
          );
        } else {
          alert(`Image reclassified to ${response.image_asset.category}`);
        }
      },
      onError: (error: Error) => {
        setMutatingCardId(null);
        alert(error);
      },
    }
  );

  return (
    <>
      <InternGenerateImageAssetDialog
        assetID={selectedAssetID}
        isDialogOpen={isGenerateImageDialogOpen}
        setIsDialogOpen={setIsGenerateImageDialogOpen}
      />
      <CardWrapper>
        <Card
          onClick={() => {
            navigate(`/intern/assets/images/${asset.id}`);
          }}
        >
          <ImageContainer>
            <Image src={asset.url} alt={asset.original_filename} />
            {asset.variant_count > 0 && (
              <VariantLabel>
                {asset.variant_count}{" "}
                {asset.variant_count > 1 ? "variants" : "variant"}
              </VariantLabel>
            )}
            {asset.source_image_asset && (
              <SourceLabel
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/intern/assets/images/${asset.source_image_asset!.id}`
                  );
                }}
              >
                <img src={asset.source_image_asset.url} alt="Variant" />
                source
              </SourceLabel>
            )}
            <CategoryLabel>
              {asset.category}
              {asset.has_analysis ? "*" : null}
            </CategoryLabel>
            <ButtonContainer>
              <Button
                size="1"
                variant="surface"
                title="Generate"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsGenerateImageDialogOpen(true);
                  setSelectedAssetID(asset.id);
                }}
              >
                <Sparkles size="12px" />
              </Button>
              <Button
                size="1"
                variant="surface"
                title="Reclassify"
                onClick={(e) => {
                  e.stopPropagation();
                  setMutatingCardId(asset.id);
                  reclassify();
                }}
              >
                <RefreshCcw size="12px" />
              </Button>
              <Button
                size="1"
                variant="surface"
                title="View in Admin"
                onClick={(e) => {
                  e.stopPropagation();
                  const currentHost = window.location.hostname;
                  const isLocalhost = currentHost === "localhost";
                  const baseUrl = isLocalhost ? "http://localhost:8000" : "";
                  window.open(
                    `${baseUrl}/admin/media/imageasset/${asset.id}/change/`
                  );
                }}
              >
                <Cog size="12px" />
              </Button>
            </ButtonContainer>
          </ImageContainer>
          <Flex justify="between" gap="8px">
            <Flex direction="column">
              <CardTitle>ID</CardTitle>
              <CardSubtitle>{asset.id}</CardSubtitle>
              <CardTitle>Source</CardTitle>
              <CardSubtitle>{asset.source}</CardSubtitle>
              <CardTitle>Commerce ID</CardTitle>
              <CardSubtitle>
                {asset.commerce_platform_item_id ?? "n/a"}
              </CardSubtitle>
            </Flex>
          </Flex>
        </Card>
        {mutatingCardId === asset.id && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}
      </CardWrapper>
    </>
  );
};

export default InternImageAssetCard;
