import GenerateSectionWithPrompt from "./generate-section/GenerateSectionWithPrompt";
import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import BrandCustomSectionPropertiesModifier from "./modifiers/BrandCustomSectionPropertiesModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ListicleModifier from "./modifiers/ListicleModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isListicleSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const ListicleView = () => {
  const { selectedSectionId, sections, id: emailCreativeId } = useEmailState();
  const updateSection = useUpdateSectionField();

  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (!isListicleSection(selectedSection) && !isNewSection(selectedSection)) {
    return null;
  }

  if (isNewSection(selectedSection)) {
    return (
      <GenerateSectionWithPrompt
        chatMessage="Please input what list content should be generated."
        section={{
          ...selectedSection,
          type: EmailSectionType.listicle,
        }}
        emailCreativeId={emailCreativeId}
      />
    );
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const items = selectedSection.list_items;

  return (
    <Flex direction="column" gap="24px">
      <GeneratedVariantSelector />

      <BrandCustomSectionPropertiesModifier />

      <TextModifier
        textElement={title}
        label="Title"
        fieldName="title.text"
        enabled={title.enabled}
        onSwitchToggle={(value) =>
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value,
          })
        }
      />
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        fieldName="subtitle.text"
        enabled={subtitle.enabled}
        onSwitchToggle={(value) =>
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value,
          })
        }
      />

      <ListicleModifier items={items} />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </Flex>
  );
};

export default ListicleView;
