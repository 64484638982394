import { operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

export type BrandEmailAnalysisUpdateRequest =
  operations["intern_api_update_brand_email_defaults"]["requestBody"]["content"]["application/json"];

const useInternBrandEmailSectionUpdateMutation = (brandId: string) => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useDraperApiPostMutation<
    void,
    BrandEmailAnalysisUpdateRequest
  >({
    mutationKey: ["internBrandEmailDefaults"],
    path: `/intern/brand/${brandId}/email-defaults`,
    onSuccess: () => {
      toast.success("Email defaults updated");
    },
    onError: () => {
      toast.error("Failed to update email defaults");
    },
  });

  return {
    updateBrandEmailSections: mutate,
    isPending,
    isSuccess,
  };
};

export default useInternBrandEmailSectionUpdateMutation;
