import { components } from "@openapi";
import _ from "lodash";

type EmailAssetsType =
  components["schemas"]["CampaignListSchema"]["email_assets"];

export const getAudiencesFromEmailAssets = (emailAssets: EmailAssetsType) =>
  _(emailAssets)
    .flatMap((emailAsset) => emailAsset.audiences)
    .uniqBy("audience_name")
    .value();

export const getExcludedAudiencesFromEmailAssets = (
  emailAssets: EmailAssetsType
) =>
  _(emailAssets)
    .flatMap((emailAsset) => emailAsset.excluded_audiences)
    .uniqBy("audience_name")

    .value();
