import { Flex, IconButton, Skeleton, Spinner, Text } from "@radix-ui/themes";
import { SquareArrowOutUpRightIcon } from "lucide-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PencilIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { currencyFormatter, formatNumberLocale } from "~/utils/helpers";
import { assertNever } from "~/utils/typeUtils";

const EditLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-grow: 0;
`;

type CampaignCardContentRowFormat = "currency" | "percentage" | "number";

const formatRowValue = ({
  value,
  format,
  currency,
}: {
  value?: string | number;
  format?: CampaignCardContentRowFormat;
  currency?: string;
}) => {
  if (!value) return "-";
  if (!format) return value.toLocaleString();
  const numValue = typeof value === "string" ? parseFloat(value) : value;
  switch (format) {
    case "currency":
      return currencyFormatter(currency ?? "USD").format(numValue);
    case "percentage":
      const formattedNumber = Math.round(numValue * 100) / 100;
      return new Intl.NumberFormat(navigator.language, {
        style: "percent",
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    case "number":
      return formatNumberLocale(numValue);
    default:
      assertNever(format);
      return value;
  }
};

export type CampaignCardContentRowCell = {
  key?: string;
  label: string;
  value?: string | number;
  format?: CampaignCardContentRowFormat;
};

const CampaignCardContentRow = ({
  title,
  cells,
  editLink,
  currency = "USD",
  disabledExternalLink,
  loadingExternalLink,
  onClickOpenExternalLink,
}: {
  title: string;
  cells?: CampaignCardContentRowCell[];
  editLink?: string;
  currency?: string;
  disabledExternalLink?: boolean;
  loadingExternalLink?: boolean;
  onClickOpenExternalLink?: () => void;
}) => {
  return (
    <Flex gapX="58px" gapY="24px" wrap="wrap" justify="between">
      <Flex gapX="58px" gapY="24px" wrap="wrap">
        <Flex width="250px" gap="15px" align="center">
          <Skeleton
            width="48px"
            height="48px"
            style={{ borderRadius: "8px", flexShrink: 0 }}
          />
          <DraperText weight="medium" wordBreak="break-word" clamp={2}>
            {title}
            {onClickOpenExternalLink && (
              <IconButton
                disabled={disabledExternalLink}
                onClick={onClickOpenExternalLink}
                style={{ display: "contents" }}
              >
                {loadingExternalLink ? (
                  <Spinner />
                ) : (
                  <SquareArrowOutUpRightIcon
                    width="20px"
                    height="20px"
                    color="var(--text-link)"
                    style={{
                      marginLeft: "5px",
                      marginBottom: "-4px",
                    }}
                  />
                )}
              </IconButton>
            )}
          </DraperText>
        </Flex>
        {cells &&
          cells.map((cell) => (
            <Flex gap="3px" key={cell.key ?? cell.label} direction="column">
              <Text size="2">{cell.label}</Text>
              <Text weight="medium">
                {formatRowValue({ ...cell, currency })}
              </Text>
            </Flex>
          ))}
      </Flex>
      {editLink && (
        <EditLink to={editLink}>
          <AppButton radius="large" size="3" variant="outlined">
            <PencilIcon />
            Edit
          </AppButton>
        </EditLink>
      )}
    </Flex>
  );
};

export default CampaignCardContentRow;
