import Handlebars from "handlebars";

export function useHandlebarsInit() {
  // doesn't seem to be used - should use `any` instead
  Handlebars.registerHelper(
    "or",
    function (
      this: any,
      a: boolean,
      b: boolean,
      options: Handlebars.HelperOptions
    ) {
      if (a || b) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    }
  );
  Handlebars.registerHelper("isEven", function (index: number): boolean {
    return index % 2 === 0;
  });
  Handlebars.registerHelper("isOdd", function (index: number): boolean {
    return index % 2 === 1;
  });
  Handlebars.registerHelper("eq", function (a: any, b: any): boolean {
    return a == b;
  });
  Handlebars.registerHelper("length", function (arr: any[]): number {
    return arr.length;
  });
  Handlebars.registerHelper("editable", function (): void {
    // Do nothing - we're only enabling this while editing
  });

  Handlebars.registerHelper(
    "default",
    (option: String, defaultValue: String) => {
      return option ?? defaultValue;
    }
  );
  Handlebars.registerHelper("chunk", function (array, size) {
    if (!Array.isArray(array)) return [];
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }

    return chunked;
  });
  Handlebars.registerHelper("take", function (array, count) {
    if (!Array.isArray(array)) return [];
    return array.slice(0, count);
  });
  Handlebars.registerHelper("and", function (): boolean {
    var args = Array.prototype.slice.call(arguments, 0, -1);
    return args.every(function (arg) {
      return !!arg;
    });
  });
  Handlebars.registerHelper("any", function (): boolean {
    var args = Array.prototype.slice.call(arguments, 0, -1);
    return args.some(function (arg) {
      return !!arg;
    });
  });
  Handlebars.registerHelper("divide", function (a: number, b: number): number {
    if (b === 0) return 0; // Prevent division by zero
    return parseFloat((a / b).toFixed(2)); // Return float with 2 decimal places
  });

  Handlebars.registerHelper("merge", function (base, overrides) {
    return { ...base, ...overrides };
  });

  Handlebars.registerHelper("array", function () {
    return Array.from(arguments).slice(0, arguments.length - 1);
  });

  Handlebars.registerHelper("makeObject", function (options) {
    return options.hash;
  });

  Handlebars.registerHelper("encodeColor", function (color) {
    return encodeURIComponent(color);
  });

  Handlebars.registerHelper("normalizeRow", function (row, size) {
    const normalized = new Array(size).fill(null);
    for (let i = 0; i < row.length; i++) {
      if (row.length === 1) {
        normalized[1] = row[i];
      } else if (row.length === 2) {
        normalized[0] = row[0];
        normalized[2] = row[1];
      } else {
        normalized[i] = row[i];
      }
    }
    return normalized;
  });

  // NOTE: mjml uses "display: inline-block" for buttons, probably for a reason...
  // but we use "display: block" to make sure the clickable area is full width
  Handlebars.registerPartial(
    "button",
    `{{#if enabled}}
<tr>
    <td align={{#if align}}"{{align}}"{{else}}"center"{{/if}} vertical-align="middle" style="
        font-size:0px;
        word-break:break-word;
        {{#if outer_padding}}padding: {{outer_padding}};{{/if}}
    ">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
            border-collapse:separate;
            line-height:100%;
            {{#if width}}width: {{width}};{{/if}}
        ">
            <tbody>
                <tr>
                    <td 
                        role="presentation" 
                        align="center"
                        valign="middle"
                        {{#if background_color}}bgcolor="{{background_color}}"{{/if}}
                        style="
                            cursor: auto;
                            {{#if background_color}}background: {{background_color}};{{/if}}
                            {{#if border_color}}border: {{border_color}};{{/if}}
                            {{#if border_radius}}border-radius: {{border_radius}};{{/if}}
                            {{#if border_style}}border-style: {{border_style}};{{/if}}
                            {{#if border_width}}border-width: {{border_width}};{{/if}}
                            {{#if padding}}mso-padding-alt: {{padding}};{{/if}}
                        "
                    >
                        <a
                            {{editable id}}
                            id="ttid:{{id}}"
                            target="_blank"
                            href="{{link_url}}"
                            style="
                                display: block;
                                cursor: pointer;
                                line-height: 120%;
                                margin: 0;
                                mso-padding-alt: 0px;
                                text-align: center;
                                text-decoration: none;
                                {{#if background_color}}background: {{background_color}};{{/if}}
                                {{#if border_radius}}border-radius: {{border_radius}};{{/if}}
                                {{#if color}}color: {{color}};{{/if}}
                                {{#if font_styles.family}}font-family: {{font_styles.family}};{{/if}}
                                {{#if font_styles.size}}font-size: {{font_styles.size}};{{/if}}
                                {{#if font_styles.style}}font-style: {{font_styles.style}};{{/if}}
                                {{#if font_styles.weight}}font-weight: {{font_styles.weight}};{{/if}}
                                {{#if padding}}padding: {{padding}};{{/if}}
                                {{#if text_transform}}text-transform: {{text_transform}};{{/if}}
                            "
                        >
                            {{{text}}}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </td>
</tr>
{{/if}}`
  );
  Handlebars.registerPartial(
    "text",
    `{{#if enabled}}
  <tr>
    <td 
      id="ttid:{{id}}" 
      style="
        {{#if text_align}}text-align: {{text_align}};{{/if}}
        {{#if color}}color: {{color}};{{/if}}
        {{#if text_decoration}}text-decoration: {{text_decoration}};{{/if}}
        padding-bottom: {{#if padding_bottom}}{{padding_bottom}}{{else}}12px{{/if}};
        word-break: break-word;
        {{style}}
      " 
      {{editable id}}
    >
        {{{text}}}
    </td>
  </tr>
  {{/if}}`
  );

  Handlebars.registerPartial(
    "two_texts",
    `{{#each texts}}
      {{#if enabled}}
      <td 
        id="ttid:{{id}}" 
        style="
          {{#if text_align}}text-align: {{text_align}};{{/if}}
          {{#if color}}color: {{color}};{{/if}}
          {{#if text_decoration}}text-decoration: {{text_decoration}};{{/if}}
          padding-bottom: {{#if padding_bottom}}{{padding_bottom}}{{else}}12px{{/if}};
          {{style}}
        " 
        {{editable id}}
      >
          {{{text}}}
      </td>
      {{/if}}
    {{/each}}`
  );

  Handlebars.registerPartial(
    "img",
    `<tr>
    <td align="center" style="font-size:0px;word-break:break-word;">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
        <tbody>
            <tr>
            <td style="
              width:{{#if width}}{{width}}{{else}}600{{/if}}px;
              {{#if padding_bottom}}padding-bottom: {{padding_bottom}};{{/if}}
            ">
              {{#if link_url}}<a href="{{link_url}}" target="_blank" style="text-decoration: none;">{{/if}}
              <img src="{{image_url}}" alt="{{alt}}" style="
                  border:0;
                  display:block;
                  outline:none;
                  text-decoration:none;
                  height:auto;
                  width:100%;
                  border-radius:{{border_radius}};
                  {{#if max_height}}max-height: {{max_height}};{{/if}}
                  {{#if min_height}}min-height: {{min_height}};{{/if}}
                  {{#if aspect_ratio}}aspect-ratio: {{aspect_ratio}};{{/if}}
                  {{#if object_fit}}object-fit: {{object_fit}};{{/if}}
                  {{#if object_position}}object-position: {{object_position}};{{/if}}
                " 
                width="{{#if width}}{{width}}{{else}}600{{/if}}" 
                height="{{#if height}}{{height}}{{else}}auto{{/if}}"
                />
              {{#if link_url}}</a>{{/if}}
            </td>
            </tr>
        </tbody>
        </table>
    </td>
    </tr>`
  );
}
