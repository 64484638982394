import useEmailRegenerateMutation, {
  GenerateEmailVariantResponse,
} from "./useEmailRegenerateMutation";
import useGenerateEmailVariantStatusQuery, {
  GenerateEmailVariantStatusResponse,
} from "./useGenerateEmailVariantStatusQuery";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

function useGenerateEmailVariantWithStatus({
  onScheduleSuccess,
  onScheduleError,
  onGenerationSuccess,
  onGenerationError,
  onComplete,
}: {
  onScheduleSuccess?: (data: GenerateEmailVariantResponse) => void;
  onScheduleError?: (error: string) => void;
  onGenerationSuccess?: (
    variant: NonNullable<GenerateEmailVariantStatusResponse["variant"]>
  ) => void;
  onGenerationError?: (error: string) => void;
  onComplete?: () => void;
}) {
  const activeBrandID = useActiveBrandID();
  const [generationSessionID, setGenerationSessionID] = useState<string | null>(
    null
  );

  const schedule = useEmailRegenerateMutation({
    onSuccess: (data) => {
      if (data.job_session_id) {
        onScheduleSuccess?.(data);
        setGenerationSessionID(data.job_session_id);
      } else {
        onScheduleError?.("An error occurred while generating new variant.");
        setGenerationSessionID(null);
      }
    },
    onError: (error) => {
      setGenerationSessionID(null);
      if (isAxiosError(error) && error.response?.status === 403) {
        onScheduleError?.(
          "You do not have permission to generate new variants for this email."
        );
      } else {
        console.log(error);
        onScheduleError?.("An error occurred while generating new variant.");
      }
    },
  });

  const generateStatusQuery = useGenerateEmailVariantStatusQuery({
    brandId: activeBrandID,
    jobSessionId: generationSessionID,
  });

  useEffect(() => {
    if (!generateStatusQuery) {
      return;
    }
    if (generateStatusQuery.error) {
      onGenerationError?.(generateStatusQuery.error);
    } else if (generationSessionID !== null && !!generateStatusQuery.variant) {
      onGenerationSuccess?.(generateStatusQuery.variant);
    }
    if (generateStatusQuery.is_finished) {
      setGenerationSessionID(null);
      onComplete?.();
    }
  }, [generateStatusQuery, generationSessionID]);

  return {
    regenerateEmail: schedule.regenerateEmail,
    isGenerating: schedule.isLoading || !!generationSessionID,
    generationSessionID,
    generateStatusQuery,
  };
}

export default useGenerateEmailVariantWithStatus;
