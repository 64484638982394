import DraperText from "../DraperText";
import ImagePreview from "../ImagePreview";
import AppButton from "../buttons/AppButton/AppButton";
import { ImageAssetSource } from "@openapi";
import { Flex } from "@radix-ui/themes";
import { useEffect, useMemo, useState } from "react";
import { CheckIcon, CropIcon, ImageIcon } from "~/assets/icons";
import AssetSelectorDialog, {
  AssetSelectorCommercePlatformItem,
  AssetSelectorItem,
  HoverOverlay,
} from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import useImageAssetsQuery from "~/hooks/media/useImageAssetsQuery";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

export type ImageSelectorProps = {
  campaignId: string | null;
  value?: string | File;
  onSelect: (assets: AssetSelectorItem[]) => void;
  isUploading?: boolean;
  alt?: string;
  buttonText?: string;
  addButtonText?: string;
  onCrop?: (file: File) => void;
  showCarousel?: boolean;
  assetsMetadata?: {
    relatedAssets?: AssetSelectorCommercePlatformItem[];
    productCommerceId?: string;
    collectionCommerceId?: string;
    /**
     * True if it should only use passed in commerce id (product/collection) for
     * picking source for image generation
     * Default: false
     */
    keepCommerceId?: boolean;
  };
};

const ImageSelector: React.FC<ImageSelectorProps> = ({
  value,
  onSelect,
  isUploading = false,
  alt,
  addButtonText = "Add Image",
  buttonText = "Change",
  onCrop,
  assetsMetadata: {
    relatedAssets,
    productCommerceId,
    collectionCommerceId,
    keepCommerceId = false,
  } = {},
  campaignId,
  showCarousel = false,
}) => {
  const activeBrandID = useActiveBrandID();

  const [cropOpen, setCropOpen] = useState(false);
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);

  const imageUrl = useMemo(() => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      return value;
    }
    return URL.createObjectURL(value);
  }, [value]);
  const { dimensions } = useGetImageDimensionsAndSize(imageUrl);
  const fileName = imageUrl ? getFilenameFromUrl(imageUrl) : undefined;

  const { assetsData } = useImageAssetsQuery({
    brandId: activeBrandID,
    search: "",
    source: [ImageAssetSource.gen_ai],
    campaignId: campaignId || undefined,
    limit: 6,
    enabled: showCarousel,
  });

  const aiGeneratedImages = useMemo(
    () => assetsData?.flatMap((data) => data.assets),
    [assetsData]
  );

  const handleCrop = (data: string) => {
    const file = dataURIToFile(
      data,
      fileName ? `${fileName}.png` : "cropped-image.png"
    );
    onCrop?.(file);
  };

  const isBusy = isUploading;

  let asset: AssetSelectorCommercePlatformItem | undefined;
  if (relatedAssets) {
    const relatedCommerceId =
      relatedAssets.find((asset) => asset.url === imageUrl)
        ?.commerce_platform_item_id ??
      productCommerceId ??
      collectionCommerceId;
    let compatibleAssets: AssetSelectorCommercePlatformItem[] | undefined;
    if (keepCommerceId && relatedCommerceId) {
      compatibleAssets = relatedAssets.filter(
        (asset) => asset.commerce_platform_item_id === relatedCommerceId
      );
      if (!compatibleAssets.length) {
        compatibleAssets = relatedAssets;
      }
    } else {
      compatibleAssets = relatedAssets;
    }

    const index = Math.floor(Math.random() * compatibleAssets.length);
    asset = compatibleAssets[index];
  }

  const assetDialog = (
    <AssetSelectorDialog
      singleAsset
      campaignId={campaignId}
      isDialogOpen={imageSelectorOpen}
      relatedAssets={relatedAssets}
      onToggleDialogOpen={(open) => setImageSelectorOpen(open)}
      onSelect={onSelect}
      preselectedAssets={
        imageUrl
          ? [
              {
                url: imageUrl,
                commerce_platform_item_id:
                  asset?.commerce_platform_item_id ?? null,
              },
            ]
          : []
      }
    />
  );

  const carouselImages: Array<
    ImageAssetSchema | AssetSelectorCommercePlatformItem
  > = [...(relatedAssets ?? []), ...(aiGeneratedImages ?? [])].filter(
    (image) => image !== undefined
  );

  useEffect(() => {
    if (imageUrl && carouselImages.length > 0) {
      const selectedImageIndex = carouselImages.findIndex(
        (image) => image.url === imageUrl
      );
      if (selectedImageIndex !== -1) {
        const imageElements = document.querySelectorAll(
          "#image-modifier-carousel .rt-Flex > div"
        );
        imageElements[selectedImageIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [imageUrl, carouselImages]);

  if (!imageUrl) {
    return (
      <>
        <AppButton
          disabled={isBusy}
          variant="outlined"
          size="3"
          radius="large"
          onClick={() => setImageSelectorOpen(true)}
        >
          <ImageIcon />
          {addButtonText}
        </AppButton>
        {assetDialog}
      </>
    );
  }

  return (
    <Flex direction="column" gap="16px">
      <Flex gap="16px">
        <ImagePreview src={imageUrl} alt={alt ?? "logo"} />

        <Flex direction="column" gap="12px" overflow="hidden">
          <DraperText clamp={2} size="2" color="gray">
            {fileName ?? ""}
          </DraperText>
          <DraperText clamp={1} size="2" color="gray">
            {dimensions}
          </DraperText>
        </Flex>
      </Flex>
      <Flex direction="column" gap="3">
        <Flex gap="3" justify="between">
          <AppButton
            disabled={isBusy}
            variant="outlined"
            size="3"
            radius="large"
            style={{
              flex: 1,
            }}
            onClick={() => setImageSelectorOpen(true)}
          >
            <ImageIcon />
            {buttonText}
          </AppButton>
          {onCrop && (
            <>
              <AppButton
                variant="outlined"
                size="3"
                radius="large"
                disabled={!imageUrl}
                style={{ flex: 1 }}
                onClick={() => setCropOpen(true)}
              >
                <CropIcon />
                Crop
              </AppButton>
              {imageUrl && (
                <CropImageDialog
                  open={cropOpen}
                  onOpenChange={setCropOpen}
                  img={imageUrl}
                  onCrop={handleCrop}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>

      {showCarousel && carouselImages && carouselImages.length > 0 && (
        <Flex
          id="image-modifier-carousel"
          width="100%"
          style={{ overflowX: "auto" }}
        >
          <Flex gap="2" style={{ minWidth: "min-content" }}>
            {(carouselImages ?? []).map((image, index) => (
              <BrandAsset
                key={index}
                asset={image}
                showGeneratedBadge={true}
                overlay={
                  <HoverOverlay $isSelected={imageUrl === image.url}>
                    <CheckIcon color="white" width="24" height="24" />
                  </HoverOverlay>
                }
                onClick={() => {
                  onSelect([image]);
                }}
                width={80}
                height={80}
              />
            ))}

            <AppButton
              variant="outlined"
              size="3"
              radius="large"
              style={{
                width: "80px",
                height: "80px",
              }}
              onClick={() => setImageSelectorOpen(true)}
            >
              <ImageIcon />
            </AppButton>
          </Flex>
        </Flex>
      )}

      {assetDialog}
    </Flex>
  );
};

export default ImageSelector;
