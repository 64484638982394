import { Flex, Separator } from "@radix-ui/themes";
import { useState } from "react";
import { SparklesIcon } from "~/assets/icons";
import ChatMessageContainer from "~/components/chat/ChatMessageContainer";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import TextAreaWithLabel from "~/components/core/inputs/TextAreaWithLabel";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation, {
  InitEmailSectionMutationParams,
} from "~/hooks/emails/useInitEmailSectionMutation";
import { EmailSection } from "~/routes/intern/email_editor/context/EmailEditorContext";

export interface GenerateSectionWithPromptProps {
  chatMessage?: string;
  section: Pick<Exclude<EmailSection, { type: null }>, "id" | "type" | "index">;
  emailCreativeId: string;
  children?: React.ReactNode;
  prepareGenerate?: (
    generate: boolean,
    promptSummary?: string
  ) => Partial<Omit<InitEmailSectionMutationParams, "brand_id">>;
}

const GenerateSectionWithPrompt: React.FC<GenerateSectionWithPromptProps> = ({
  chatMessage = "Please input what content should be generated.",
  section,
  emailCreativeId,
  children,
  prepareGenerate,
}) => {
  const activeBrandID = useActiveBrandID();
  const [promptSummary, setPromptSummary] = useState<string>("");
  const { mutate: initEmailSection, isPending: isInitEmailSectionLoading } =
    useInitEmailSectionMutation();

  const onGenerateOrWriteManually = (
    generate: boolean,
    promptSummary?: string
  ) => {
    const options = prepareGenerate?.(generate, promptSummary);
    initEmailSection({
      brand_id: activeBrandID,
      section_type: section.type,
      id: section.id,
      index: section.index,
      generate,
      email_creative_id: emailCreativeId,
      prompt_summary: promptSummary,
      ...options,
    });
  };

  return (
    <Flex direction="column" gap="24px">
      <Flex gap="16px">
        <ChatMessageContainer message={chatMessage} />
      </Flex>
      <TextAreaWithLabel
        labelColor="var(--text-tertiary)"
        label="Prompt"
        value={promptSummary}
        onChange={(e) => setPromptSummary(e.target.value)}
      />

      {children}

      <Separator size="4" />
      <Flex justify="end" gap="16px">
        <AppButton
          onClick={() => onGenerateOrWriteManually(false)}
          variant="outlined"
          size="3"
          radius="large"
          disabled={isInitEmailSectionLoading}
        >
          Write manually
        </AppButton>
        <AppButton
          onClick={() => onGenerateOrWriteManually(true, promptSummary)}
          variant="dark"
          radius="large"
          size="3"
          disabled={isInitEmailSectionLoading || !promptSummary.trim()}
        >
          <SparklesIcon />
          {isInitEmailSectionLoading ? "Generating..." : "Generate"}
        </AppButton>
      </Flex>
    </Flex>
  );
};

export default GenerateSectionWithPrompt;
