import { useActiveBrandID } from "../../../contexts/CurrentUserContext";
import { SiMeta } from "@icons-pack/react-simple-icons";
import { operations } from "@openapi";
import { Card, Container, Flex, Heading, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ChevronRight, Mail } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { INTERN_CAMPAIGNS_ROUTE } from "~/routes/constants";

type CampaignsApiResponse =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"];

const InternCampaignsComponent = () => {
  const navigate = useNavigate();
  const styles = {
    header: {
      display: "flex",
      justifyContent: "space-between",
      margin: "5px 0px 25px",
    },
  };

  const activeBrandID = useActiveBrandID();

  const query = useQuery<CampaignsApiResponse>({
    queryKey: ["campaigns-list"],
    queryFn: async (): Promise<CampaignsApiResponse> => {
      const { data } = await axios.get("/api/v1/campaign/list", {
        params: {
          brand_id: activeBrandID,
        },
      });
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  return (
    <Container>
      <div style={styles.header}>
        <Heading>Campaigns</Heading>
      </div>
      <Card>
        <Flex direction="column">
          {query.data?.campaigns.map((campaign) => (
            <Flex
              key={campaign.id}
              direction="column"
              gap="8px"
              style={{
                cursor: "pointer",
                borderBottom: "solid",
                borderColor: "#dfdfdf",
                borderWidth: "1px",
                paddingTop: "16px",
                paddingBottom: "16px",
                width: "100%",
              }}
              onClick={() => {
                navigate(`${INTERN_CAMPAIGNS_ROUTE}/${campaign.id}`);
              }}
            >
              <Flex justify="between">
                <Flex direction="column" gap="4px">
                  <Heading size="2">Campaign</Heading>
                  <Text size="1">ID: {campaign.id}</Text>
                  <Text size="1">Status: {campaign.status}</Text>
                  <Text size="1">Destination: {campaign.destination}</Text>
                  <Flex gap="2">
                    <Flex align="center" gap="4px">
                      <Text size="2">{campaign.email_count}</Text>
                      <Mail size="16px" />
                    </Flex>
                    <Flex align="center" gap="4px">
                      <Text size="2">{campaign.ad_count}</Text>
                      <SiMeta size="16px" />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex direction="column" gap="4px">
                  <Heading size="2">Recommendation</Heading>
                  <Text size="1">
                    ID: {campaign.campaign_recommendation?.id}
                  </Text>
                </Flex>
                <Flex justify="end" gap="1em" align="center">
                  <ChevronRight
                    style={{
                      height: "1.5rem",
                      width: "1.5rem",
                      paddingLeft: "0.25rem",
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Card>
    </Container>
  );
};

export default InternCampaignsComponent;
