import { AdMediaAspectRatio } from "@openapi";
import { Box, Button, Checkbox, Flex, Skeleton, Text } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { PencilIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";

const Root = styled.div`
  border-radius: 20px;
  border: 1px solid var(--border_primary, #dddddd);
  box-shadow: 0px 3px 8px 0px #58422d0a;
  box-shadow: 0px 12px 16px 0px #58422d0a;
  box-shadow: 0px 16px 36px 0px #58422d0a;
  cursor: pointer;
  width: 100%;
  flex-basis: 100%;
  display: flex;
  padding: 24px;
  background-color: var(--primary-white);
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Image = styled.img`
  height: 120px;
  object-fit: cover;
  background-color: var(--gray-5);
  border-radius: 8px;
`;

const CampaignPublishDialogAdCard = ({
  title,
  contentHeader,
  contentSubheader,
  adId,
  previewImages,
  onSelect,
  selected,
}: {
  onSelect?: () => void;
  selected: boolean;
  title: string;
  contentHeader: string;
  contentSubheader: string;
  adId: string;
  previewImages: {
    url: string;
    aspect_ratio: AdMediaAspectRatio;
  }[];
}) => {
  return (
    <Root onClick={onSelect}>
      <Box width={"100%"}>
        <Header>
          <Text as="label" size="2">
            <Flex gap="2">
              {onSelect && <Checkbox checked={selected} />}
              <Text size="2" weight="bold">
                {title}
              </Text>
            </Flex>
          </Text>
          <Link
            to={`/ad/${adId}`}
            reloadDocument
            onClick={(e) => e.stopPropagation()}
          >
            <Button size={"2"} radius="large" color="gray" variant="ghost">
              <PencilIcon size={16} /> Edit
            </Button>
          </Link>
        </Header>

        <Flex direction={"row"} gap="16px">
          {!!previewImages.length ? (
            previewImages.map((image, idx) => (
              <Image
                key={idx}
                src={image.url}
                style={{
                  aspectRatio:
                    image.aspect_ratio ===
                    AdMediaAspectRatio.full_screen_vertical
                      ? "9 / 16"
                      : image.aspect_ratio === AdMediaAspectRatio.landscape
                      ? "1.91 / 1"
                      : image.aspect_ratio === AdMediaAspectRatio.portrait
                      ? "4 / 5"
                      : "1 / 1",
                }}
                alt="Ad image"
              />
            ))
          ) : (
            <>
              <Skeleton width="120px" height="120px" />
              <Skeleton width="82px" height="120px" />
            </>
          )}

          <Flex direction={"column"}>
            <Text as="div" size="2" weight="bold">
              {contentHeader}
            </Text>
            <DraperText
              color="gray"
              size="2"
              clamp={4}
              style={{ wordBreak: "break-word" }}
            >
              {contentSubheader}
            </DraperText>
          </Flex>
        </Flex>
      </Box>
    </Root>
  );
};

export default CampaignPublishDialogAdCard;
