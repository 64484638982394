import CampaignStatusIndicator from "../CampaignStatusIndicator";
import { components, EmailCreativeStatus } from "@openapi";
import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Skeleton,
  Text,
} from "@radix-ui/themes";
import _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { styled } from "styled-components";
import { PencilIcon } from "~/assets/icons";
import AudiencePill from "~/components/assets/selector/AudiencePill";
import AudienceDialog from "~/components/dialogs/AudienceDialog";
import { usePatchEmailCreativeMutation } from "~/hooks/emails/usePatchEmailCreativeMutation";

const Root = styled.div`
  border-radius: 20px;
  border: 1px solid var(--border_primary, #dddddd);
  box-shadow: 0px 3px 8px 0px #58422d0a;
  box-shadow: 0px 12px 16px 0px #58422d0a;
  box-shadow: 0px 16px 36px 0px #58422d0a;
  cursor: pointer;
  width: 100%;
  flex-basis: 100%;
  display: flex;
  padding: 24px;
  background-color: var(--primary-white);
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Image = styled.img`
  width: 120px;
  height: 160px;
  border: 1px solid var(--border_primary, #dddddd);
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
  background-color: var(--gray-5);
`;

const CampaignPublishDialogEmailCard = ({
  audiences,
  audienceExclusions,
  title,
  status,
  contentHeader,
  contentSubheader,
  publishedAt,
  emailId,
  campaignId,
  previewImage,
  onSelect,
  selected,
}: {
  onSelect?: () => void;
  audiences: components["schemas"]["CDPAudienceRequestData"][];
  audienceExclusions: components["schemas"]["CDPAudienceRequestData"][];
  selected: boolean;
  status: EmailCreativeStatus;
  campaignId: string;
  emailId: string;
  previewImage: string | null;
  title: string;
  contentHeader: string;
  contentSubheader: string;
  publishedAt: string | null;
}) => {
  const patchEmailCreative = usePatchEmailCreativeMutation({
    campaignId,
    onSuccess: () => {
      toast.success(`Email updated`);
    },
    onError: () => {
      toast.error(`Failed to update email`);
    },
  });
  const isAudienceMissing =
    audiences.length === 0 && audienceExclusions.length === 0;
  const audienceComponent = isAudienceMissing ? (
    <>
      <AudiencePill />
      <Flex onClick={(e) => e.stopPropagation()} align="center">
        <AudienceDialog
          initialAudiences={audiences}
          trigger={
            <IconButton size="1" variant="ghost" color="gray">
              <PencilIcon size={16} />
            </IconButton>
          }
          onConfirm={(audiences) => {
            patchEmailCreative.mutate({
              id: emailId,
              audiences,
            });
          }}
        />
      </Flex>
    </>
  ) : (
    <>
      {audiences.map((audience) => (
        <AudiencePill key={audience.audience_id} audience={audience} />
      ))}
      {audienceExclusions.map((audience) => (
        <AudiencePill
          key={audience.audience_id}
          audience={audience}
          variant="exclude"
        />
      ))}
    </>
  );
  return (
    <Root onClick={onSelect}>
      <Flex width={"100%"} direction={"column"} gap="16px">
        <Header>
          <Text as="label" size="2">
            <Flex gap="2" align={"center"}>
              {onSelect && <Checkbox checked={selected} />}
              <Text size="2" weight="bold">
                {title}
              </Text>
              <CampaignStatusIndicator
                status={status}
                publishedAt={publishedAt}
              />
            </Flex>
          </Text>
          <Link
            to={`/campaign/${campaignId}/email/${emailId}`}
            reloadDocument
            onClick={(e) => e.stopPropagation()}
          >
            <Button size={"2"} radius="large" color="gray" variant="ghost">
              <PencilIcon size={16} /> Edit
            </Button>
          </Link>
        </Header>

        <Flex gap="2" align="center" wrap="wrap">
          {audienceComponent}
        </Flex>

        <Flex direction={"row"} gap="16px">
          {!!previewImage ? (
            <Image src={previewImage} alt="Email image" />
          ) : (
            <Skeleton width="120px" height="160px" />
          )}

          <Flex direction={"column"}>
            <Text as="div" size="2" weight="bold">
              {contentHeader}
            </Text>
            <Text as="div" color="gray" size="2">
              {contentSubheader}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Root>
  );
};

export default CampaignPublishDialogEmailCard;
