const EmailSectionPreview = ({ html }: { html: string }) => {
  return (
    <div
      style={{
        // We want to isolate the email preview styles from the website styles
        // These are just ones we've seen so far that are causing issues
        // iFrame's are hard because of our use of DnD and our extensive use of ReactContexts
        all: "unset",
        lineHeight: "normal",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
};

export default EmailSectionPreview;
