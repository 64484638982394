import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export type GenerateEmailVariantStatusRequestData =
  operations["emails_api_generate_email_variant_status"]["parameters"]["query"];

export type GenerateEmailVariantStatusResponse =
  operations["emails_api_generate_email_variant_status"]["responses"][200]["content"]["application/json"] & {
    error?: string;
  };

export type GenerateEmailVariantStatusProps = {
  brandId: string;
  jobSessionId: string | null;
};

const useGenerateEmailVariantStatusQuery = ({
  brandId,
  jobSessionId,
}: GenerateEmailVariantStatusProps) => {
  const { data } = useQuery<GenerateEmailVariantStatusResponse>({
    queryKey: [
      "emails_api_generate_email_variant_status",
      brandId,
      jobSessionId,
    ],
    queryFn: async (): Promise<GenerateEmailVariantStatusResponse> => {
      const params: GenerateEmailVariantStatusRequestData = {
        brand_id: brandId,
        job_session_id: jobSessionId!,
      };
      const response = await axios
        .get("/api/v1/emails/email-variant-gen-status", {
          params,
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        })
        .catch((error) => {
          console.log(
            "Error in fetching email variant generation status",
            error
          );
          return {
            data: {
              is_finished: true,
              error: "Failed to generate email variant",
              image_asset: null,
            },
          };
        });
      return response.data;
    },
    enabled: jobSessionId !== null,
    refetchInterval: 3000,
    retry: false,
    staleTime: Infinity,
  });
  return data;
};

export default useGenerateEmailVariantStatusQuery;
