import ModifierContainer from "./ModifierContainer";
import { ImageAssetCategory, ImageAssetSource } from "@openapi";
import { CheckIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";
import { Flex, TextField } from "@radix-ui/themes";
import { useEffect, useMemo } from "react";
import { toast } from "sonner";
import { ImageIcon } from "~/assets/icons";
import {
  AssetSelectorCommercePlatformItem,
  AssetSelectorItem,
  HoverOverlay,
} from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGetEmailRelatedImages from "~/hooks/emails/useGetEmailRelatedImages";
import useImageAssetsQuery from "~/hooks/media/useImageAssetsQuery";
import useUploadImageAssets, {
  getFormImageAssetCategory,
} from "~/hooks/style-library/useUploadImageAssets";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const ImageModifier = ({
  initialImageUrl,
  initialLinkUrl,
  onAssetChange,
  productId,
  collectionId,
  onLinkUrlChange,
}: {
  initialImageUrl?: string;
  initialLinkUrl?: string;
  collectionId?: string | null;
  productId?: string | null;
  onAssetChange: (assets: AssetSelectorItem) => void;
  onLinkUrlChange?: (linkUrl: string) => void;
}) => {
  const { campaign_id } = useEmailState();
  const activeBrandID = useActiveBrandID();

  const { images: relatedAssets } = useGetEmailRelatedImages({
    productId,
    collectionId,
  });

  const { mutate: uploadImageAssets, isPending: isUploading } =
    useUploadImageAssets({
      onSuccess(data) {
        onAssetChange?.(data.new_assets[0] as ImageAssetSchema);
      },
      onError() {
        toast.error("Failed to upload image");
      },
    });
  const handleUpload = (file: File) => {
    uploadImageAssets({
      brand_id: activeBrandID,
      category: getFormImageAssetCategory(ImageAssetCategory.other),
      images: [file as unknown as string],
    });
  };

  return (
    <ModifierContainer
      title={initialImageUrl ? "Image" : "Featured Image (optional)"}
      hideSwitch
    >
      <ImageSelector
        campaignId={campaign_id}
        value={initialImageUrl}
        onSelect={(assets) => onAssetChange(assets[0])}
        onCrop={handleUpload}
        isUploading={isUploading}
        assetsMetadata={{
          relatedAssets: relatedAssets,
          productCommerceId: productId ?? undefined,
          collectionCommerceId: collectionId ?? undefined,
          keepCommerceId: true,
        }}
        showCarousel
      />

      {initialLinkUrl && onLinkUrlChange && (
        <Flex direction="column" gap="8px">
          <Label htmlFor="linkUrl">Link URL</Label>
          <TextField.Root
            required
            name="link"
            value={initialLinkUrl}
            onChange={(e) => {
              onLinkUrlChange(e.target.value);
            }}
            type="url"
            placeholder="https://www.example.com"
          />
        </Flex>
      )}
    </ModifierContainer>
  );
};

export default ImageModifier;
