import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type InternImageAssetAnalysisResponse =
  operations["intern_api_get_image_asset_analysis"]["responses"][200]["content"]["application/json"];

export const getImageAssetAnalysisQueryKey = (imageAssetId?: string) => [
  "image-asset-analysis",
  imageAssetId,
];

const useInternGetImageAssetAnalysis = ({
  imageAssetId,
}: {
  imageAssetId?: string;
}) => {
  const imageAssetQuery = useQuery<InternImageAssetAnalysisResponse>({
    queryKey: getImageAssetAnalysisQueryKey(imageAssetId),
    queryFn: async () => {
      const { data } = await axios.get(
        `/api/v1/intern/assets/image/${imageAssetId}/analysis`,
        {}
      );
      return data;
    },
    retry: false,
    staleTime: 3600,
    enabled: !!imageAssetId,
  });
  return imageAssetQuery;
};

export default useInternGetImageAssetAnalysis;
