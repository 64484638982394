import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useEmailCreativePreviewQuery = (emailCreativeId: string) => {
  const {
    data: previewHtml,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
    error,
  } = useQuery<string>({
    queryKey: ["email-creative-preview", emailCreativeId],
    queryFn: async (): Promise<string> => {
      const { data } = await axios.get<string>(
        `/api/v1/emails/email-creative/${emailCreativeId}/preview-html`
      );
      return data;
    },
    enabled: !!emailCreativeId,
  });

  return {
    previewHtml: previewHtml ?? null,
    isLoading: isLoading || isFetching,
    isSuccess,
    error,
    refetch,
  };
};

export default useEmailCreativePreviewQuery;
