import ModifierContainer from "./ModifierContainer";
import SectionTextModifier from "./SectionTextModifier";
import { components } from "@openapi";
import { Card, Flex, Switch } from "@radix-ui/themes";
import { GripVertical } from "lucide-react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import DraperText from "~/components/core/DraperText";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import { cleanHtmlText } from "~/components/editor/utils";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const ListCard = ({
  listicleElement,
  fieldName,
  enabled,
  onSwitchToggle,
}: {
  listicleElement: components["schemas"]["EmailTextListElementSchema"];
  fieldName: string;
  enabled: boolean;
  onSwitchToggle: (enabled: boolean) => void;
}) => {
  return (
    <Card>
      <Flex align="center" justify="between" mb="2" gap="2">
        <Flex align="center" gap="2">
          <GripVertical size={20} />
          <DraperText size={"3"} weight={"medium"} clamp={1}>
            {cleanHtmlText(listicleElement.title.text)}
          </DraperText>
        </Flex>
        <Switch
          checked={enabled}
          onCheckedChange={onSwitchToggle}
          color="gray"
          highContrast
        />
      </Flex>
      <Flex direction="column" gap="2">
        <SectionTextModifier
          textElement={listicleElement.title}
          fieldName={`${fieldName}.title.text`}
          label="Title"
        />

        <SectionTextModifier
          textElement={listicleElement.subtitle}
          fieldName={`${fieldName}.subtitle.text`}
          label="Subtitle"
        />
      </Flex>
    </Card>
  );
};

const ListicleModifier = ({
  items,
}: {
  items: components["schemas"]["EmailTextListElementSchema"][];
}) => {
  const updateSection = useUpdateSectionField();
  const { selectedSectionId } = useEmailState();

  return (
    <DragDropContext
      onDragEnd={(result: DropResult) => {
        const { destination, source } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId !== source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }
        const newListItems = Array.from(items);
        const [reorderedProduct] = newListItems.splice(source.index, 1);
        newListItems.splice(destination.index, 0, reorderedProduct);

        updateSection({
          sectionId: selectedSectionId,
          field: "list_items",
          value: newListItems,
        });
      }}
    >
      <ModifierContainer title="Listicle" hideSwitch={true}>
        <StrictModeDroppable
          droppableId="listicle-modifier-droppable"
          type="group"
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Flex direction="column" gap="2">
                {items.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id || String(index)}
                      draggableId={item.id || String(index)}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <ListCard
                            fieldName={`list_items.${index}`}
                            listicleElement={item}
                            enabled={item.enabled}
                            onSwitchToggle={(enabled) => {
                              updateSection({
                                sectionId: selectedSectionId,
                                field: `list_items.${index}.enabled`,
                                value: enabled,
                              });
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Flex>
            </div>
          )}
        </StrictModeDroppable>
      </ModifierContainer>
    </DragDropContext>
  );
};

export default ListicleModifier;
