import { Flex, Text } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { STYLE_LIBRARY_ROUTE_TEMPLATE_EDITOR } from "~/routes/constants";

const HeaderView = () => {
  const navigate = useNavigate();
  return (
    <Flex direction="column" gap="16px">
      <Text>Footer can be edited from the Template Editor.</Text>
      <AppButton
        radius="large"
        variant="soft"
        onClick={() => navigate(STYLE_LIBRARY_ROUTE_TEMPLATE_EDITOR)}
      >
        Edit Header Template
      </AppButton>
    </Flex>
  );
};

export default HeaderView;
