import { AssetSelectorItem, HoverOverlay } from "../AssetSelectorDialog";
import { Flex } from "@radix-ui/themes";
import { CheckIcon } from "lucide-react";
import BrandAsset from "~/components/style-library/assets/BrandAsset";

const RelatedImages = ({
  relatedAssets,
  selectAsset,
  selectedAssets,
}: {
  relatedAssets?: AssetSelectorItem[] /** should contain urls */;
  selectAsset: (asset: AssetSelectorItem) => void;
  selectedAssets: AssetSelectorItem[];
}) => {
  if (!relatedAssets?.length) {
    return null;
  }
  return (
    <Flex wrap="wrap" gap="24px">
      {relatedAssets.map((asset) => (
        <BrandAsset
          key={asset.url}
          asset={asset}
          overlay={
            <HoverOverlay
              $isSelected={selectedAssets
                .map((asset) => asset.url)
                .includes(asset.url)}
            >
              <CheckIcon color="white" width="24" height="24" />
            </HoverOverlay>
          }
          onClick={() => selectAsset(asset)}
        />
      ))}
    </Flex>
  );
};
export default RelatedImages;
