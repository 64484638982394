import CampaignCardContentRow from "./CampaignCardContentRow";
import { components, operations } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { MailIcon, MetaIcon } from "~/assets/icons";
import AudiencePill from "~/components/assets/selector/AudiencePill";
import useKlaviyoLinkQuery from "~/hooks/emails/useKlaviyoLinkQuery";
import {
  getAudiencesFromEmailAssets,
  getExcludedAudiencesFromEmailAssets,
} from "~/utils/my-campaigns/helpers";

type CampaignType = components["schemas"]["CampaignListSchema"];
type EmailMetricType =
  operations["cdp_api_klaviyo_metrics"]["responses"][200]["content"]["application/json"];
type AdMetricType =
  operations["meta_api_get_ad_analytics"]["responses"][200]["content"]["application/json"];

const Content = styled(Flex)`
  padding: 24px;
  border-top: 1px solid var(--border);
  color: var(--text-secondary);
`;

const CampaignCardContent = ({
  campaign,
  emailMetrics,
  adMetrics,
}: {
  campaign: CampaignType;
  emailMetrics: EmailMetricType;
  adMetrics: Partial<AdMetricType>;
}) => {
  const [triggeredEmailId, setTriggeredEmailId] = useState<string | null>(null);
  const [externalLinkWindow, setExternalLinkWindow] = useState<Window | null>(
    null
  );
  const klaviyoLinkQuery = useKlaviyoLinkQuery({
    emailCreativeId: triggeredEmailId,
  });

  useEffect(() => {
    if (!triggeredEmailId || !klaviyoLinkQuery.data) {
      return;
    }
    externalLinkWindow?.location?.replace(klaviyoLinkQuery.data);
    externalLinkWindow?.focus();
    setExternalLinkWindow(null);

    setTriggeredEmailId(null);
  }, [triggeredEmailId, klaviyoLinkQuery.data, externalLinkWindow]);

  useEffect(() => {
    if (!klaviyoLinkQuery.error || !externalLinkWindow) {
      return;
    }
    externalLinkWindow?.location?.replace(
      "https://www.klaviyo.com/campaigns/list"
    );
    externalLinkWindow?.focus();
    setExternalLinkWindow(null);
  }, [externalLinkWindow, klaviyoLinkQuery.error]);

  if (campaign.email_assets.length + campaign.ad_assets.length === 0) {
    return (
      <Content justify="center" align="center">
        <Text align="center">No Emails or Ads</Text>
      </Content>
    );
  }

  const isScheduledCampaign = campaign.status === "scheduled";

  return (
    <Content direction="column" gap="16px">
      {campaign.email_assets.length > 0 && (
        <Flex gap="16px" direction="column">
          <Flex gap="8px" align="center" wrap="wrap">
            <MailIcon strokeWidth="1" width={20} height={20} />
            <Text>Email</Text>
            {getAudiencesFromEmailAssets(campaign.email_assets).map(
              (audience) => (
                <AudiencePill key={audience.audience_id} audience={audience} />
              )
            )}
            {getExcludedAudiencesFromEmailAssets(campaign.email_assets).map(
              (audience) => (
                <AudiencePill
                  key={audience.audience_id}
                  audience={audience}
                  variant="exclude"
                />
              )
            )}
          </Flex>
          {campaign.email_assets.map((emailAsset) => {
            const metrics =
              emailMetrics?.[emailAsset?.klaviyo_campaign_id ?? ""];
            return (
              <CampaignCardContentRow
                key={emailAsset.id}
                title={emailAsset.title}
                cells={[
                  {
                    label: "Orders",
                    value: metrics?.orders,
                    format: "number",
                  },
                  {
                    label: "Revenue",
                    value: metrics?.revenue,
                    format: "currency",
                  },
                  {
                    label: "Delivered",
                    value: metrics?.delivered,
                    format: "percentage",
                  },
                  {
                    label: "Open Rate",
                    value: metrics?.open_rate,
                    format: "percentage",
                  },
                  {
                    label: "CTR",
                    value: metrics?.ctr,
                    format: "percentage",
                  },
                ]}
                editLink={
                  !isScheduledCampaign
                    ? `/campaign/${campaign.id}/email/${emailAsset.id}`
                    : undefined
                }
                disabledExternalLink={!!triggeredEmailId}
                loadingExternalLink={
                  !!klaviyoLinkQuery.isLoading &&
                  triggeredEmailId === emailAsset.id
                }
                onClickOpenExternalLink={
                  emailAsset.klaviyo_campaign_id
                    ? () => {
                        const w = window.open("", "_blank");
                        setExternalLinkWindow(w);
                        setTriggeredEmailId(emailAsset.id);
                      }
                    : undefined
                }
              />
            );
          })}
        </Flex>
      )}
      {campaign.ad_assets.length > 0 && (
        <Flex gap="16px" direction="column">
          <Flex gap="8px" align="center">
            <MetaIcon size="20" />
            <Text>Meta Ads</Text>
          </Flex>
          {campaign.ad_assets.map((adAsset) => {
            const metrics = adMetrics?.metrics?.[adAsset?.id ?? ""];
            return (
              <CampaignCardContentRow
                key={adAsset.id}
                title={adAsset.title}
                cells={
                  metrics
                    ? [
                        {
                          label: "Orders",
                          value: metrics.orders,
                          format: "number",
                        },
                        {
                          label: "Revenue",
                          value: metrics.revenue,
                          format: "currency",
                        },
                        {
                          label: "Spent",
                          value: metrics.spent,
                          format: "currency",
                        },
                        {
                          label: "Estimated Profit",
                          value: metrics.estimated_profit,
                          format: "currency",
                        },
                      ]
                    : [
                        adAsset.adset_name
                          ? {
                              label: "Connected To Meta",
                              value: adAsset.adset_name,
                            }
                          : {
                              label: "Not connected",
                              value: "",
                            },
                      ]
                }
                editLink={
                  !isScheduledCampaign ? `/ad/${adAsset.id}` : undefined
                }
                currency={adMetrics.currency}
                // TODO: neeeds to set the following up once publishing ads is done
                disabledExternalLink={true}
                loadingExternalLink={false}
                onClickOpenExternalLink={undefined}
              />
            );
          })}
        </Flex>
      )}
    </Content>
  );
};

export default CampaignCardContent;
