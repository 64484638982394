import { Flex, SegmentedControl, Text } from "@radix-ui/themes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { MonitorIcon, SmartphoneIcon } from "~/assets/icons";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import ConfirmationAlert from "~/components/core/dialog/ConfirmationAlert";
import EditorTopBar from "~/components/core/editor/layout/EditorTopBar";
import {
  useTemplateEditorDispatch,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";
import useUpdateBrandEmailSections from "~/hooks/style-library/useUpdateBrandEmailSections";
import { STYLE_LIBRARY_ROUTE } from "~/routes/constants";

const TemplateEditorTopControlBar = () => {
  const { previewMode } = useTemplateEditorState();
  const dispatch = useTemplateEditorDispatch();

  const navigate = useNavigate();
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);
  const updateBrandEmailSections = useUpdateBrandEmailSections({
    onSuccess: () => {
      toast.success("Template updated successfully");
    },
    onError: (errorMessage, axiosError) => {
      console.log(
        "Error updating brand email sections:",
        errorMessage,
        axiosError
      );
      toast.error("Something went wrong");
    },
  });

  const handleCancel = () => {
    navigate("/style-library");
  };

  const handleSave = () => {
    updateBrandEmailSections.mutate();
  };

  return (
    <>
      <EditorTopBar
        backButtonDestination={STYLE_LIBRARY_ROUTE}
        left={
          <Text ml="12px" size="4" weight="medium">
            Template
          </Text>
        }
        center={
          <DraperSegmentControl
            style={{ paddingTop: "6px", backgroundColor: "#F0EDEB" }}
            size="3"
            value={previewMode}
            onValueChange={(val) =>
              dispatch({
                type: "UPDATE_EDITOR_STATE",
                payload: {
                  previewMode: val as "desktop" | "mobile",
                },
              })
            }
          >
            <SegmentedControl.Item value="desktop">
              <MonitorIcon />
            </SegmentedControl.Item>
            <SegmentedControl.Item value="mobile">
              <SmartphoneIcon />
            </SegmentedControl.Item>
          </DraperSegmentControl>
        }
        right={
          <Flex gap="12px" align="center">
            <AppButton
              variant="primary"
              radius="full"
              onClick={handleSave}
              disabled={updateBrandEmailSections.isPending}
            >
              {updateBrandEmailSections.isPending ? "Saving..." : "Save"}
            </AppButton>
          </Flex>
        }
      />

      <ConfirmationAlert
        open={isCancelConfirmOpen}
        onOpenChange={setIsCancelConfirmOpen}
        title="Cancel current changes?"
        description="Are you sure? Some data might get lost"
        onConfirm={handleCancel}
        confirmText="Discard changes"
      />
    </>
  );
};

export default TemplateEditorTopControlBar;
