import { Popover, TextField } from "@radix-ui/themes";
import dayjs from "dayjs";
import { CalendarDays } from "lucide-react";
import { useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import styled from "styled-components";

const CalendarInput = styled.input`
  all: unset;
  cursor: pointer;
  background: white;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type="date"] {
    -moz-appearance: textfield;
    position: relative;
    z-index: 2;
    background: transparent;
  }
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const StyledDayPicker = styled(DayPicker)`
  --rdp-accent-color: var(--icon-primary-active);
`;

const DatePicker = ({
  onSelect,
}: {
  onSelect: (formattedDate: string) => void;
}) => {
  const [selected, setSelected] = useState<Date>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const handleDaySelect = (date: Date | undefined) => {
    if (!date) {
      // don't allow deselection, as we always want a date to be selected
      return;
    }
    setSelected(date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    if (inputRef.current) {
      inputRef.current.value = formattedDate;
    }
    onSelect(formattedDate);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <TextField.Root
          style={{
            height: "48px",
            borderRadius: "12px",
            padding: "12px",
            background: "white",
          }}
        >
          <CalendarInput
            ref={inputRef}
            type="date"
            onClick={(e) => {
              setOpen(true);
              e.preventDefault();
            }}
          />
          {!/firefox/i.test(navigator.userAgent) && ( // seems very hard to hide the calendar icon on firefox
            <TextField.Slot
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setOpen(true);
                e.preventDefault();
              }}
            >
              <CalendarDays size={20} />
            </TextField.Slot>
          )}
        </TextField.Root>
      </Popover.Trigger>
      <Popover.Content sideOffset={20} align="start">
        <StyledDayPicker
          mode="single"
          selected={selected}
          onSelect={handleDaySelect}
          defaultMonth={selected}
          disabled={{ before: new Date() }}
        />
      </Popover.Content>
    </Popover.Root>
  );
};

export default DatePicker;
