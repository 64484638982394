import CloseIconButton from "../../../../core/buttons/CloseIconButton";
import AssetSelectorDialogGenerateView from "./AssetSelectorDialogGenerateView";
import AIGeneratedImages from "./sections/AIGeneratedImages";
import AssetSelectorSections from "./sections/AssetSelectorSections";
import LibraryAssets from "./sections/LibraryAssets";
import RelatedImages from "./sections/RelatedImages";
import { ImageAssetCategory } from "@openapi";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Dialog, Flex, Heading, TextField } from "@radix-ui/themes";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Toaster } from "sonner";
import styled from "styled-components";
import { SparklesIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import UploadImageButton from "~/components/core/buttons/UploadImageButton";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useUploadImageAssets from "~/hooks/style-library/useUploadImageAssets";

export const HoverOverlay = styled.div<{ $isSelected?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.$isSelected ? 1 : 0)};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export type AssetSelectorCommercePlatformItem = {
  url: string;
  commerce_platform_item_id: string | null;
};

export type AssetSelectorItem =
  | AssetSelectorCommercePlatformItem
  | ImageAssetSchema;

const AssetSelectorDialog = ({
  campaignId,
  singleAsset,
  isDialogOpen,
  onToggleDialogOpen,
  preselectedAssets,
  onSelect,
  relatedAssets,
}: {
  campaignId: string | null;
  singleAsset?: boolean;
  isDialogOpen: boolean;
  onToggleDialogOpen: (open: boolean) => void;
  preselectedAssets?: AssetSelectorItem[];
  relatedAssets?: AssetSelectorItem[] /** Items might only contain a url (which may or may or may not be an asset) */;
  onSelect: (assets: AssetSelectorItem[]) => void;
}) => {
  const activeBrandID = useActiveBrandID();

  const [selectedAssets, setSelectedAssets] = useState<AssetSelectorItem[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isGenerateImageViewActive, setIsGenerateImageViewActive] =
    useState<boolean>(false);

  // SEARCH
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (isDialogOpen) {
      setSelectedAssets(preselectedAssets ?? []);
      setIsGenerateImageViewActive(false);
    }
  }, [isDialogOpen]);

  const {
    mutate: uploadImageAssetsMutation,
    isPending: isUploadImageAssetMutationPending,
  } = useUploadImageAssets({
    onSuccess: (data) => {
      setSelectedAssets(data.new_assets);
      handleSearchChange("");
    },
    onError: (error) => {
      if (error.response?.status === 403) {
        setUploadError?.("You do not have permission to upload images.");
      } else {
        console.log(error);
        setUploadError?.("An error occurred while uploading images.");
      }
    },
  });

  const debouncedSearchCall = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchCall.cancel();
    };
  }, [debouncedSearchCall]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    debouncedSearchCall(value);
  };

  const selectAsset = (asset: AssetSelectorItem) => {
    if (singleAsset) {
      setSelectedAssets([asset]);
    } else {
      !!selectedAssets.find((file) => file.url === asset.url)
        ? setSelectedAssets(
            selectedAssets.filter((file) => file.url !== asset.url)
          )
        : setSelectedAssets([...selectedAssets, asset]);
    }
  };

  // adding preselected images to the start of the "related images" section
  const completeRelatedAssets = useMemo(
    () =>
      [
        ...(preselectedAssets ?? []),
        ...(relatedAssets ?? [])?.filter(
          (asset) =>
            !preselectedAssets?.map((asset) => asset.url)?.includes(asset.url)
        ),
      ].slice(0, 6),
    [preselectedAssets, relatedAssets]
  );

  let header = (
    <Flex direction="column">
      <Flex
        justify="between"
        align="center"
        p="16px"
        pb="12px"
        style={{ borderBottom: "1px solid #E2E2E2" }}
      >
        <Dialog.Title size="4" weight="bold" mb="0">
          Select Image
        </Dialog.Title>
        <Dialog.Close>
          <CloseIconButton />
        </Dialog.Close>
      </Flex>

      <Flex gap="5" p="16px" align="center">
        <TextField.Root
          value={search}
          onChange={(event) => handleSearchChange(event.target.value)}
          style={{
            height: "40px",
            backgroundColor: "#F1F1F0",
            width: "calc(100% - 24px)",
          }}
          radius="large"
          color="teal"
          variant="soft"
          placeholder="Search…"
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
        <UploadImageButton
          brandID={activeBrandID}
          category={ImageAssetCategory.other}
          setUploadError={setUploadError}
          onUpload={uploadImageAssetsMutation}
          isLoading={isUploadImageAssetMutationPending}
        />
      </Flex>
      {uploadError && (
        <Heading size="1" color="red" align={"center"} mb="2">
          {uploadError}
        </Heading>
      )}
    </Flex>
  );
  let content = (
    <Flex direction="column" px="24px" py="16px" gap="3">
      <AssetSelectorSections
        sections={[
          campaignId
            ? {
                title: "AI Generated",
                section: (
                  <AIGeneratedImages
                    selectAsset={selectAsset}
                    search={debouncedSearch}
                    selectedAssets={selectedAssets}
                    campaignId={campaignId}
                  />
                ),
              }
            : null,
          relatedAssets
            ? {
                title: "Related Images",
                section: (
                  <RelatedImages
                    relatedAssets={completeRelatedAssets}
                    selectAsset={selectAsset}
                    selectedAssets={selectedAssets}
                  />
                ),
              }
            : null,
          {
            title: "Library Assets",
            section: (
              <LibraryAssets
                search={debouncedSearch}
                selectedAssets={selectedAssets}
                selectAsset={selectAsset}
              />
            ),
          },
        ]}
      />
    </Flex>
  );
  let footer = (
    <Flex
      px="24px"
      py="12px"
      justify="between"
      gap="3"
      style={{ borderTop: "1px solid #E2E2E2" }}
    >
      <AppButton
        disabled={selectedAssets.length !== 1}
        variant="soft"
        size="3"
        radius="large"
        onClick={() => {
          setIsGenerateImageViewActive(true);
        }}
      >
        <SparklesIcon size="20px" fill="var(--primary-deep-purple)" />
        Generate from Selected
      </AppButton>

      <Flex gap="3">
        <Dialog.Close>
          <AppButton variant="outlined" size="3" radius="large">
            Cancel
          </AppButton>
        </Dialog.Close>
        <Dialog.Close>
          <AppButton
            disabled={!selectedAssets.length}
            onClick={() => onSelect(selectedAssets)}
            variant="primary"
            size="3"
            radius="large"
          >
            Use Selected Image{selectedAssets.length > 1 ? "s" : ""}
          </AppButton>
        </Dialog.Close>
      </Flex>
    </Flex>
  );
  let allContent = (
    <>
      {header}
      <div
        style={{
          overflowY: !isGenerateImageViewActive ? "auto" : undefined,
          maxHeight: !isGenerateImageViewActive ? "70vh" : undefined,
        }}
      >
        {content}
      </div>
      {footer}
    </>
  );

  if (isGenerateImageViewActive) {
    allContent = (
      <AssetSelectorDialogGenerateView
        sourceAsset={selectedAssets[0]}
        campaignId={campaignId}
        onSelect={onSelect}
        onBack={() => setIsGenerateImageViewActive(false)}
      />
    );
  }

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={onToggleDialogOpen}>
      <Dialog.Content maxWidth="780px" style={{ padding: 0 }}>
        <Toaster richColors />
        {allContent}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AssetSelectorDialog;
