import { DROPZONE_IMAGE_TYPES } from "../dropzone/Dropzone";
import AppButton from "./AppButton/AppButton";
import { ImageAssetCategory } from "@openapi";
import { Text } from "@radix-ui/themes";
import { Dispatch, SetStateAction } from "react";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "~/assets/icons";

export const UploadImageButton = ({
  brandID,
  category,
  setUploadError,
  onUpload,
  isLoading,
}: {
  brandID: string;
  category: ImageAssetCategory;
  setUploadError?: Dispatch<SetStateAction<string | null>>;
  onUpload: (data: any) => any;
  isLoading?: boolean;
}) => {
  const handleUpload = (acceptedFiles: File[]) => {
    onUpload({
      brand_id: brandID,
      category: category,
      images: acceptedFiles as unknown as string[],
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: DROPZONE_IMAGE_TYPES,
  });
  return (
    <div {...getRootProps()}>
      <AppButton
        loading={isLoading}
        variant="outlined"
        size="3"
        radius="large"
        onClick={() => setUploadError?.(null)}
      >
        <UploadIcon size={20} />
        <Text size="3" weight="medium">
          Upload
        </Text>
      </AppButton>
      <input {...getInputProps()} />
    </div>
  );
};

export default UploadImageButton;
