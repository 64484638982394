import LoadingDots from "../../../misc/LoadingDots";
import { AssetSelectorItem, HoverOverlay } from "../AssetSelectorDialog";
import { ImageAssetSource } from "@openapi";
import { CheckIcon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";
import { useMemo } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useImageAssetsQuery from "~/hooks/media/useImageAssetsQuery";

const AIGeneratedImages = ({
  selectAsset,
  search,
  selectedAssets,
  campaignId,
}: {
  selectAsset: (asset: AssetSelectorItem) => void;
  search: string;
  selectedAssets: AssetSelectorItem[];
  campaignId: string;
}) => {
  const activeBrandID = useActiveBrandID();

  const { assetsData, isLoading, error, fetchNextPage, hasNextPage } =
    useImageAssetsQuery({
      brandId: activeBrandID,
      search: search,
      source: [ImageAssetSource.gen_ai],
      campaignId: campaignId || undefined,
      limit: 6,
    });

  const assetImages = useMemo(
    () => assetsData?.flatMap((data) => data.assets),
    [assetsData]
  );

  if (error) {
    return <Text>Failed to fetch assets</Text>;
  }
  return (
    <Flex direction="column" gap="2">
      <Flex
        wrap="wrap"
        gap="24px"
        style={{ gridTemplateColumns: "repeat(6, 1fr)", display: "grid" }}
      >
        {(assetImages ?? []).map((asset) => (
          <BrandAsset
            key={asset.id}
            asset={asset}
            originalFilename={asset.original_filename}
            overlay={
              <HoverOverlay
                $isSelected={
                  !!selectedAssets.find((file) => file.url === asset.url)
                }
              >
                <CheckIcon color="white" width="24" height="24" />
              </HoverOverlay>
            }
            onClick={() => {
              selectAsset(asset);
            }}
          />
        ))}
      </Flex>
      {hasNextPage && !isLoading && (
        <Flex justify="start">
          <AppButton
            radius="large"
            size="3"
            variant="outlined"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage}
          >
            Load More
          </AppButton>
        </Flex>
      )}

      {isLoading && (
        <Flex justify={"center"} align={"center"} my="5">
          <LoadingDots />
        </Flex>
      )}
    </Flex>
  );
};

export default AIGeneratedImages;
