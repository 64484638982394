import ModifierContainer from "./ModifierContainer";
import { Flex, Text } from "@radix-ui/themes";
import { useState } from "react";
import NumberInput from "~/components/core/inputs/NumberInput";

const RadiusModifier = ({
  radius,
  onChange,
}: {
  radius: string;
  onChange: (value: string) => void;
}) => {
  const [value, setValue] = useState<string>(radius);

  return (
    <ModifierContainer title="Image Corner Radius" hideSwitch={true}>
      <Flex gap="1" align="center">
        <NumberInput
          radius="large"
          value={parseInt(value)}
          style={{
            width: "75px",
            overflow: "hidden",
          }}
          onValueChange={(value) => {
            const valueString = `${value}px`;
            setValue(valueString);
            onChange(valueString);
          }}
        />
        <Text size="2">px</Text>
      </Flex>
    </ModifierContainer>
  );
};

export default RadiusModifier;
