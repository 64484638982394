import useInternClassifyCampaignImageAssets, {
  ClassifyCampaignImageAssetsResponse,
} from "./useInternClassifyCampaignImageAssets";
import useInternGetClassifyCampaignImageAssetsStatus, {
  ClassifyCampaignImageAssetsStatusResponse,
} from "./useInternGetClassifyCampaignImageAssetsStatus";
import { useEffect, useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

function useInternClassifyCampaignImageAssetsWithStatus({
  onScheduleSuccess,
  onScheduleError,
  onSuccess,
  onError,
  onComplete,
}: {
  onScheduleSuccess?: (data: ClassifyCampaignImageAssetsResponse) => void;
  onScheduleError?: (error: string) => void;
  onSuccess?: (data: ClassifyCampaignImageAssetsStatusResponse) => void;
  onError?: (error: string) => void;
  onComplete?: () => void;
}) {
  const activeBrandID = useActiveBrandID();
  const [sessionID, setSessionID] = useState<string | null>(null);

  const schedule = useInternClassifyCampaignImageAssets({
    onSuccess: (data) => {
      if (data.job_session_id) {
        onScheduleSuccess?.(data);
        setSessionID(data.job_session_id);
      } else {
        onScheduleError?.("An error occurred while classifying images.");
        setSessionID(null);
      }
    },
    onError: (error) => {
      setSessionID(null);
      if (error.response?.status === 403) {
        onScheduleError?.(
          "You do not have permission to classify campaign images."
        );
      } else {
        console.log(error);
        onScheduleError?.("An error occurred while classifying images.");
      }
    },
  });

  const status = useInternGetClassifyCampaignImageAssetsStatus({
    brandId: activeBrandID,
    jobSessionId: sessionID,
  });

  useEffect(() => {
    if (!status) {
      return;
    }
    if (status.error) {
      setSessionID(null);
      onError?.(status.error);
    }
    if (status.is_finished) {
      setSessionID(null);
      onSuccess?.(status);
      onComplete?.();
    }
  }, [status, sessionID]);

  return {
    classifyCampaignImageAssetsMutation: schedule.mutate,
    isClassifying: schedule.isPending || !!sessionID,
    sessionID,
    status,
  };
}

export default useInternClassifyCampaignImageAssetsWithStatus;
