import CloseIconButton from "../../../../core/buttons/CloseIconButton";
import { AssetSelectorItem, HoverOverlay } from "./AssetSelectorDialog";
import {
  components,
  ImageAssetOperation,
  ImageAssetOrientation,
  ImageAssetSource,
} from "@openapi";
import { CheckIcon } from "@radix-ui/react-icons";
import { Dialog, Flex, TextArea, Text } from "@radix-ui/themes";
import { useQueryClient, InfiniteData } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { SparklesIcon } from "~/assets/icons";
import ImagePreview from "~/components/core/ImagePreview";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGenerateImageAssetWithStatus from "~/hooks/media/useGenerateImageAssetWithStatus";
import useImageAssetBackgroundPromptQuery from "~/hooks/media/useImageAssetBackgroundPromptQuery";
import useImageAssetsQuery, {
  getImageAssetsQueryKey,
} from "~/hooks/media/useImageAssetsQuery";

const AssetSelectorDialogGenerateView = ({
  sourceAsset,
  campaignId,
  onSelect,
  onBack,
}: {
  sourceAsset: AssetSelectorItem;
  campaignId: string | null;
  onSelect: (assets: AssetSelectorItem[]) => void;
  onBack: () => void;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();

  const [generatedViewPreviewImages, setGeneratedViewPreviewImages] = useState<
    ImageAssetSchema[]
  >([]);
  const [generateImagePrompt, setGenerateImagePrompt] = useState<string>("");
  const [
    selectedGeneratedViewPreviewImage,
    setSelectedGeneratedViewPreviewImage,
  ] = useState<ImageAssetSchema | null>(null);

  const generateViewParams = useMemo(() => {
    return {
      brandId: activeBrandID,
      campaignId: campaignId ?? undefined,
      source: [ImageAssetSource.gen_ai],
      sourceImageId: (sourceAsset as ImageAssetSchema)?.id,
      commercePlatformItemId: sourceAsset.commerce_platform_item_id
        ? [sourceAsset.commerce_platform_item_id]
        : undefined,
    };
  }, [sourceAsset, activeBrandID, campaignId]);

  const { assetsData } = useImageAssetsQuery({
    ...generateViewParams,
  });
  const generateViewKey = getImageAssetsQueryKey(generateViewParams);
  const assetImages = useMemo(
    () => assetsData?.flatMap((data) => data.assets),
    [assetsData]
  );

  const {
    backgroundPrompt,
    isLoading: isBackgroundPromptLoading,
    isSuccess: isBackgroundPromptSuccess,
    error: backgroundPromptError,
  } = useImageAssetBackgroundPromptQuery({
    brand_id: activeBrandID,
    image_url: sourceAsset.url,
  });

  useEffect(() => {
    if (isBackgroundPromptSuccess && backgroundPrompt) {
      setGenerateImagePrompt(backgroundPrompt);
    }
  }, [isBackgroundPromptSuccess, backgroundPrompt]);

  const {
    generateImageSessionID,
    generateImageMutation,
    isGenerating: isGenerateImageMutationPending,
  } = useGenerateImageAssetWithStatus({
    onScheduleError(error) {},
    onGenerationSuccess(data) {
      if (data.image_asset) {
        setGeneratedViewPreviewImages([
          ...generatedViewPreviewImages,
          data.image_asset,
        ]);
        setSelectedGeneratedViewPreviewImage(data.image_asset);
      }

      const scrollableTarget = document.getElementById(
        "generate-view-scrollable-container"
      );
      scrollableTarget?.scrollTo({
        behavior: "smooth",
        left: 0,
      });
    },
    onGenerationError(error) {
      toast.error(error);
    },
    onComplete(data) {
      if (data.image_asset) {
        // Adds image to the front of the carousel
        queryClient.setQueryData(
          generateViewKey,
          (
            oldData:
              | InfiniteData<
                  {
                    assets: components["schemas"]["ImageAssetSchema"][];
                    next_offset: number | null;
                  },
                  unknown
                >
              | undefined
          ) =>
            //adding new asset to the top of first page
            oldData
              ? {
                  ...oldData,
                  pages: [
                    {
                      ...oldData.pages[0],
                      assets: [data.image_asset, ...oldData.pages[0].assets],
                    },
                    ...oldData.pages.slice(1),
                  ],
                }
              : oldData
        );

        // Also add it to the AI Generated Images dialog query
        queryClient.setQueryData(
          getImageAssetsQueryKey({
            brandId: activeBrandID,
            source: [ImageAssetSource.gen_ai],
            campaignId: campaignId ?? undefined,
            limit: 6,
            search: "",
          }),
          (
            oldData:
              | InfiniteData<
                  {
                    assets: components["schemas"]["ImageAssetSchema"][];
                    next_offset: number | null;
                  },
                  unknown
                >
              | undefined
          ) =>
            //adding new asset to the top of first page
            oldData
              ? {
                  ...oldData,
                  pages: [
                    {
                      ...oldData.pages[0],
                      assets: [data.image_asset, ...oldData.pages[0].assets],
                    },
                    ...oldData.pages.slice(1),
                  ],
                }
              : oldData
        );
      }
    },
  });

  const handleGenerateImage = () => {
    const commerce_id = sourceAsset.commerce_platform_item_id ?? null;
    const image_asset_id = (sourceAsset as ImageAssetSchema)?.id ?? null;

    generateImageMutation({
      brand_id: activeBrandID,
      operation: ImageAssetOperation.generate_hero,
      orientation: ImageAssetOrientation.landscape,
      subject_horizontal_alignment: null,
      subject_vertical_alignment: null,
      image_asset_id: image_asset_id,
      image_url: sourceAsset.url,
      commerce_platform_item_id: commerce_id,
      prompt: generateImagePrompt || null,
      campaign_id: campaignId,
    });
    setSelectedGeneratedViewPreviewImage(sourceAsset as ImageAssetSchema);
  };

  const areGenerateViewButtonsDisabled =
    isGenerateImageMutationPending || generateImageSessionID !== null;

  const header = (
    <Flex
      justify="between"
      align="center"
      p="16px"
      pb="12px"
      style={{ borderBottom: "1px solid #E2E2E2" }}
    >
      <Dialog.Title size="4" weight="bold" mb="0">
        Generate Image
      </Dialog.Title>
      <Dialog.Close>
        <CloseIconButton />
      </Dialog.Close>
    </Flex>
  );

  const content = (
    <Flex direction="column" p="24px" gap="5">
      <Flex direction="row" gap="4">
        <BrandAsset
          asset={sourceAsset}
          originalFilename={
            (sourceAsset as ImageAssetSchema)?.original_filename
          }
        />

        <Flex direction="column" gap="2" style={{ flex: 1 }}>
          <label
            htmlFor="prompt"
            style={{
              fontSize: "14px",
              fontWeight: 500,
              color: "var(--text-primary)",
            }}
          >
            Image Generation Prompt (optional)
          </label>
          <TextArea
            size="2"
            radius="large"
            resize="vertical"
            value={generateImagePrompt}
            onChange={(e) => setGenerateImagePrompt(e.target.value)}
            disabled={
              isGenerateImageMutationPending || isBackgroundPromptLoading
            }
            placeholder={
              isBackgroundPromptLoading
                ? "Loading suggested prompt..."
                : "e.g. create an elegant image with a background of a beach"
            }
          />
          <Flex justify="end" mt="2">
            <AppButton
              variant="soft"
              size="3"
              radius="large"
              disabled={
                areGenerateViewButtonsDisabled || isBackgroundPromptLoading
              }
              onClick={handleGenerateImage}
            >
              <SparklesIcon size="16px" fill="var(--primary-deep-purple)" />
              {isGenerateImageMutationPending
                ? "Generating Image..."
                : "Generate Image"}
            </AppButton>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        width="100%"
        direction="column"
        justify="center"
        align="center"
        style={{
          backgroundColor: "#EDEBF0",
          borderRadius: "12px",
        }}
      >
        <Flex
          justify="center"
          align="center"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {selectedGeneratedViewPreviewImage?.url ? (
            <ImagePreview
              maxHeight="300px"
              maxWidth="100%"
              showBorder={false}
              src={selectedGeneratedViewPreviewImage.url}
              alt={
                (selectedGeneratedViewPreviewImage as ImageAssetSchema)
                  ?.original_filename
              }
              isLoading={isGenerateImageMutationPending}
            />
          ) : (
            <Flex
              justify="center"
              align="center"
              style={{
                height: "300px",
                width: "100%",
              }}
            >
              <Text color="gray">No image selected</Text>
            </Flex>
          )}
        </Flex>

        {assetImages && assetImages.length > 0 && (
          <Flex
            id="generate-view-scrollable-container"
            width="100%"
            style={{ overflowX: "auto" }}
          >
            <Flex gap="5" px="5" py="3" style={{ minWidth: "min-content" }}>
              {(assetImages ?? []).map((image, index) => (
                <BrandAsset
                  key={image.id}
                  asset={image}
                  overlay={
                    <HoverOverlay
                      $isSelected={
                        selectedGeneratedViewPreviewImage !== null &&
                        selectedGeneratedViewPreviewImage.id === image.id
                      }
                    >
                      <CheckIcon color="white" width="24" height="24" />
                    </HoverOverlay>
                  }
                  onClick={() => {
                    setSelectedGeneratedViewPreviewImage(image);
                  }}
                />
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
  const footer = (
    <Flex
      px="24px"
      py="12px"
      justify="end"
      gap="3"
      style={{ borderTop: "1px solid #E2E2E2" }}
    >
      <AppButton
        variant="outlined"
        size="3"
        radius="large"
        disabled={areGenerateViewButtonsDisabled}
        onClick={() => {
          onBack();
        }}
      >
        Back
      </AppButton>

      <Dialog.Close>
        <AppButton
          disabled={
            areGenerateViewButtonsDisabled ||
            selectedGeneratedViewPreviewImage === null
          }
          onClick={() => {
            onSelect([selectedGeneratedViewPreviewImage as AssetSelectorItem]);
          }}
          variant="primary"
          size="3"
          radius="large"
        >
          Use Image
        </AppButton>
      </Dialog.Close>
    </Flex>
  );

  return (
    <>
      {header}
      <div>{content}</div>
      {footer}
    </>
  );
};

export default AssetSelectorDialogGenerateView;
