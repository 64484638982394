import React from "react";
import { toast } from "sonner";
import {
  AssetSelectorCommercePlatformItem,
  AssetSelectorItem,
} from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import ImageSelector, {
  ImageSelectorProps,
} from "~/components/core/inputs/ImageSelector";
import {
  useAdEditorDispatch,
  useAdEditorSelectedAdMediaState,
} from "~/contexts/AdEditorContext";
import useGetProductRelatedImages from "~/hooks/shopify/useGetProductRelatedImages";
import { downloadFile } from "~/utils/fileUtils";

type AdImageSelectorProps = Omit<
  ImageSelectorProps,
  "assetsMetadata" | "onSelect" | "onGenerateComplete" | "campaignId"
> & {
  productId?: string | null;
  onUpload: (file: File) => void;
  hideGenerate?: boolean;
};
const AdImageSelector: React.FC<AdImageSelectorProps> = ({
  productId,
  onUpload,
  hideGenerate = false,
  ...props
}) => {
  const { images: relatedAssets } = useGetProductRelatedImages(productId);
  const { ad } = useAdEditorSelectedAdMediaState();
  const adDispatch = useAdEditorDispatch();
  const defaultRelatedAssets: AssetSelectorCommercePlatformItem[] =
    ad?.data.campaign_recommended_items
      ?.filter((item) => item.image_url)
      .map((product) => ({
        url: product.image_url as string,
        commerce_platform_item_id: product.commerce_platform_item_id,
      })) ?? [];

  const handleImageSelected = async (assets: AssetSelectorItem[]) => {
    const file = await downloadFile(assets[0].url);
    if (!file) {
      toast.error("Failed to retrieve image");
      return;
    }

    adDispatch({
      type: "SET_IS_DIRTY",
      payload: true,
    });
    onUpload(file);
  };

  const handleCrop = (file: File) => {
    adDispatch({
      type: "SET_IS_DIRTY",
      payload: true,
    });
    props.onCrop?.(file);
  };

  return (
    <ImageSelector
      {...props}
      campaignId={ad?.data.campaign.id ?? ""}
      assetsMetadata={{
        relatedAssets: [
          ...(relatedAssets ?? []),
          ...(defaultRelatedAssets ?? []),
        ],
        productCommerceId: productId ?? undefined,
      }}
      onSelect={handleImageSelected}
      onCrop={handleCrop}
    />
  );
};

export default AdImageSelector;
