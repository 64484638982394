import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type InternImageAssetResponse =
  operations["intern_api_get_image_asset"]["responses"][200]["content"]["application/json"];

export const getImageAssetQueryKey = (imageAssetId?: string) => [
  "image-asset-",
  imageAssetId,
];

const useInternGetImageAsset = ({
  imageAssetId,
}: {
  imageAssetId?: string;
}) => {
  const imageAssetQuery = useQuery<InternImageAssetResponse>({
    queryKey: getImageAssetQueryKey(imageAssetId),
    queryFn: async () => {
      const { data } = await axios.get(
        `/api/v1/intern/assets/image/${imageAssetId}`,
        {}
      );
      return data;
    },
    retry: false,
    staleTime: 3600,
    enabled: !!imageAssetId,
  });
  return imageAssetQuery;
};

export default useInternGetImageAsset;
