import { components } from "@openapi";
import styled from "styled-components";

export type ImageAssetSchema = components["schemas"]["ImageAssetSchema"];

interface BrandImageAssetProps {
  url: string;
  width?: number;
  height?: number;
}

const Container = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${({ width }) => (width ? width + "px" : "100px")};
  height: ${({ height }) => (height ? height + "px" : "100px")};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: var(--radius-3);
  border: 1px solid rgb(228, 228, 231);
  align-content: center;
  overflow: hidden;

  & > svg {
    width: 100% !important;
  }
`;

const BrandImageAsset: React.FC<BrandImageAssetProps> = ({
  url,
  width,
  height,
}) => {
  return (
    <Container
      style={{
        backgroundImage: `url(${url})`,
      }}
      width={width}
      height={height}
    />
  );
};

export default BrandImageAsset;
