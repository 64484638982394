import { useAddImageAssetToQuery } from "../media/useImageAssetsQuery";
import {
  ImageAssetCategory,
  operations,
  PathsApiV1MediaAssetsImagesUploadPostRequestBodyMultipartFormDataCategory,
} from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

export type UploadImageAssetsParams =
  operations["media_api_upload_image_assets"]["requestBody"]["content"]["multipart/form-data"];

export type UploadImageAssetsResponse =
  operations["media_api_upload_image_assets"]["responses"][200]["content"]["application/json"];

export const getFormImageAssetCategory = (
  category: ImageAssetCategory
): PathsApiV1MediaAssetsImagesUploadPostRequestBodyMultipartFormDataCategory => {
  return PathsApiV1MediaAssetsImagesUploadPostRequestBodyMultipartFormDataCategory[
    ImageAssetCategory[
      category
    ] as keyof typeof PathsApiV1MediaAssetsImagesUploadPostRequestBodyMultipartFormDataCategory
  ];
};

const useUploadImageAssets = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: UploadImageAssetsResponse) => void;
  onError?: (error: any) => void;
}) => {
  const addImageAssetToQuery = useAddImageAssetToQuery();
  const uploadImageAssets = useMutation<
    UploadImageAssetsResponse,
    AxiosError,
    UploadImageAssetsParams
  >({
    mutationFn: async (params) => {
      const formData = new FormData();
      formData.append("brand_id", params.brand_id);
      formData.append("category", params.category);
      params.images.forEach((file) => {
        formData.append("images", file);
      });
      const { data } = await axios.post(
        "/api/v1/media/assets/images/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      console.log("Images uploaded successfully");
      addImageAssetToQuery(data.new_assets);
      onSuccess?.(data);
    },
    onError: (error) => {
      console.error("Error uploading images:", error);
      onError?.(error);
    },
  });

  return { ...uploadImageAssets };
};

export default useUploadImageAssets;
