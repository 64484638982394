import { operations } from "../../../openapi";
import { useQueryClient } from "@tanstack/react-query";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

export type UpdateEmailSectionBrandResponse =
  operations["emails_api_update_email_section_brand"]["responses"][200]["content"]["application/json"];

type UpdateEmailSectionBrandRequestData =
  operations["emails_api_update_email_section_brand"]["requestBody"]["content"]["application/json"];

const useEmailSectionBrandUpdateMutation = ({
  brandID,
  onSuccess,
  onError,
  onMutate,
}: {
  brandID: string;
  onSuccess?: (data: UpdateEmailSectionBrandResponse) => void;
  onError?: (error: Error) => void;
  onMutate?: () => void;
}) => {
  const updateEmailSectionBrand = useDraperApiPostMutation<
    UpdateEmailSectionBrandResponse,
    UpdateEmailSectionBrandRequestData
  >({
    mutationKey: ["email-section-brand-update"],
    path: `/emails/section/brand`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onMutate: (data) => {
      onMutate?.();
    },
  });

  return {
    updateEmailSectionBrand: updateEmailSectionBrand.mutate,
    isLoading: updateEmailSectionBrand.isPending,
  };
};

export default useEmailSectionBrandUpdateMutation;
