import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import AssetSelectorDialog from "../../../components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import Dropzone from "../../../components/core/dropzone/Dropzone";
import { CampaignCardCommonProps } from "./CampaignCards";
import useCampaignCustomAssetsProps from "./hooks/useCampaignCustomAssetsProps";
import { ImageAssetCategory } from "@openapi";
import { Box, Flex, Text } from "@radix-ui/themes";
import { XIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useUploadImageAssets, {
  getFormImageAssetCategory,
} from "~/hooks/style-library/useUploadImageAssets";

const CampaignCustomAssets: React.FC<CampaignCardCommonProps> = (props) => {
  const activeBrandID = useActiveBrandID();

  const {
    cardProps,
    props: {
      selectedAssets,
      setSelectedAssets,
      hasSelectedNo,
      setHasSelectedNo,
      isDialogOpen,
      onToggleDialogOpen,
    },
  } = useCampaignCustomAssetsProps(props);

  const droppedFiles = selectedAssets;
  const removeDroppedFile = (fileName: string) => {
    setSelectedAssets(
      droppedFiles.filter((file) => file.original_filename !== fileName)
    );
  };

  const { mutate: uploadImageAssets, isPending: isUploading } =
    useUploadImageAssets({
      onSuccess: (data) => {
        setSelectedAssets([...data.new_assets, ...selectedAssets]);
      },
      onError: (error) => {
        // TODO
      },
    });

  const handleUpload = (acceptedFiles: File[]) => {
    uploadImageAssets({
      brand_id: activeBrandID,
      category: getFormImageAssetCategory(ImageAssetCategory.other),
      images: acceptedFiles as unknown as string[],
    });
  };

  const assetTiles = (
    <Box
      style={{
        marginTop: "16px",
        display: "flex",
        flexWrap: "wrap",
        gap: "12px",
      }}
    >
      {droppedFiles.map((file) => (
        <Flex
          align="center"
          justify="between"
          key={file.original_filename}
          style={{
            padding: "4px",
            borderRadius: "8px",
            backgroundColor: "#F0EDEB",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <img
            src={file.url}
            alt={file.original_filename}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "8px",
              objectFit: "cover",
              marginRight: "12px",
            }}
          />
          <Text as="p" style={{ fontSize: "14px", marginRight: "12px" }}>
            {file.original_filename}
          </Text>
          <XIcon
            onClick={() => removeDroppedFile(file.original_filename)}
            strokeWidth={1.5}
            style={{
              marginLeft: "12px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
          />
        </Flex>
      ))}
    </Box>
  );

  return (
    <BrandStylingProvider>
      <CampaignWizardCard
        {...cardProps}
        subtitle="Do you have a hero asset or set of images you want to use in this campaign?"
        {...props}
      >
        <Flex gap="16px">
          <AppButton
            onClick={() => {
              setHasSelectedNo((prev) => !prev);
              setSelectedAssets([]);
            }}
            style={{
              backgroundColor: hasSelectedNo ? "#0000000D" : undefined,
            }}
            radius="large"
            variant="outlined"
          >
            No
          </AppButton>
          <AppButton
            onClick={() => onToggleDialogOpen(true)}
            radius="large"
            variant="outlined"
          >
            Choose from asset library
          </AppButton>
        </Flex>
        <Dropzone
          onDrop={(acceptedFiles: File[]) => {
            handleUpload(acceptedFiles);
          }}
          variant="link"
          disabled={isUploading}
        />
        {assetTiles}
        <AssetSelectorDialog
          campaignId={null}
          isDialogOpen={isDialogOpen}
          onToggleDialogOpen={onToggleDialogOpen}
          preselectedAssets={selectedAssets}
          onSelect={(assetItems) => {
            setSelectedAssets(
              assetItems
                .filter((assetItem) => assetItem)
                .map((assetItem) => assetItem as ImageAssetSchema)
            );
          }}
        />
      </CampaignWizardCard>
    </BrandStylingProvider>
  );
};

export default CampaignCustomAssets;
