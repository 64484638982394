import { components } from "@openapi";
import { useMemo } from "react";
import { toast } from "sonner";
import { AssetSelectorItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { getEmailStateImageUrl } from "~/types/template-vars";
import { downloadFile } from "~/utils/fileUtils";

const LogoSelector = ({
  img,
  onUpload,
}: {
  img: components["schemas"]["EmailImageElementSchema"] | null;
  onUpload: (img: File) => void;
}) => {
  const imageUrl = useMemo(() => {
    if (!img) return undefined;
    return getEmailStateImageUrl(img);
  }, [img]);

  const handleImageSelected = async (assets: AssetSelectorItem[]) => {
    const file = await downloadFile(assets[0].url);
    if (!file) {
      toast.error("Failed to retrieve image");
      return;
    }
    onUpload(file);
  };

  return (
    <ImageSelector
      campaignId={null}
      value={imageUrl}
      onSelect={handleImageSelected}
      buttonText="Change Logo"
      addButtonText="Add Logo"
    />
  );
};

export default LogoSelector;
