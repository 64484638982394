import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import BlogPostModifier from "./modifiers/BlogPostModifier";
import BrandCustomSectionPropertiesModifier from "./modifiers/BrandCustomSectionPropertiesModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import RadiusModifier from "./modifiers/RadiusModfier";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isBlogSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const BlogView = () => {
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (isNewSection(selectedSection)) {
    return null;
  }

  if (!isBlogSection(selectedSection)) {
    return null;
  }

  return (
    <>
      <GeneratedVariantSelector />

      <BrandCustomSectionPropertiesModifier />

      <BlogPostModifier blogElement={selectedSection} />

      <RadiusModifier
        radius={selectedSection.image.border_radius}
        onChange={(value) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "image.border_radius",
            value: value,
          });
        }}
      />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default BlogView;
