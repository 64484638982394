import { Flex } from "@radix-ui/themes";
import { AlertDialog } from "@radix-ui/themes";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInternBrandEmailQuery from "~/hooks/intern/useInternBrandEmailQuery";
import useInternBrandEmailSectionUpdateMutation from "~/hooks/intern/useInternBrandEmailSectionUpdateMutation";

const InternBrandEmailTab = () => {
  const activeBrandID = useActiveBrandID();
  const { brandEmailData, isLoading, isSuccess } =
    useInternBrandEmailQuery(activeBrandID);
  const {
    updateBrandEmailSections,
    isPending,
    isSuccess: isUpdateSuccess,
  } = useInternBrandEmailSectionUpdateMutation(activeBrandID);

  return (
    <Flex direction="column" gap={"2"}>
      {JSON.stringify(brandEmailData)}
      <AlertDialog.Root>
        <AlertDialog.Trigger>
          <AppButton>Update Email Defaults</AppButton>
        </AlertDialog.Trigger>
        <AlertDialog.Content>
          <AlertDialog.Title>Update Email Defaults</AlertDialog.Title>
          <AlertDialog.Description>
            Are you sure you want to update the email defaults? This will affect
            all future emails.
          </AlertDialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <AppButton variant="outlined">Cancel</AppButton>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <AppButton
                onClick={() =>
                  updateBrandEmailSections({
                    sections: brandEmailData ?? [],
                  })
                }
              >
                Update
              </AppButton>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Flex>
  );
};

export default InternBrandEmailTab;
