import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

type ReclassifyImageAssetResponse =
  operations["intern_api_reclassify_image_asset"]["responses"][200]["content"]["application/json"];

type UseInternReclassifyImageAssetOptions = {
  onSuccess?: (response: ReclassifyImageAssetResponse) => void;
  onError?: (error: Error) => void;
};

const useInternReclassifyImageAsset = (
  imageAssetId?: string,
  queryKeys?: any[],
  { onSuccess, onError }: UseInternReclassifyImageAssetOptions = {}
) => {
  const queryClient = useQueryClient();

  const mutate = useMutation<ReclassifyImageAssetResponse, Error, void>({
    mutationFn: async (): Promise<ReclassifyImageAssetResponse> => {
      const { data } = await axios.post(
        `/api/v1/intern/assets/image/${imageAssetId}/reclassify`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (response) => {
      if (queryKeys) {
        queryClient.invalidateQueries({ queryKey: queryKeys });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  return mutate;
};

export default useInternReclassifyImageAsset;
