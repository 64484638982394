import { operations } from "@openapi";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type GetCampaignsResponse =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"];
export type Campaigns =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"];
export type Campaign =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"][0];
export type CampaignStatus =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"][0]["status"];
export type ChannelsType =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"][0]["channels"];

export const getCampaignsQueryKey = (
  brandID: string,
  status: CampaignStatus,
  includeTotalCount?: boolean
) => ["campaign/list", brandID, status, includeTotalCount];

const useGetCampaigns = ({
  page = 0,
  status,
  limit = 6,
  includeTotalCount = false,
}: {
  page?: number;
  status: CampaignStatus;
  limit?: number;
  includeTotalCount?: boolean;
}) => {
  const activeBrandID = useActiveBrandID();

  return useInfiniteQuery({
    queryKey: getCampaignsQueryKey(activeBrandID, status, includeTotalCount),
    queryFn: async ({ pageParam }): Promise<GetCampaignsResponse> => {
      const { data } = await axios.get("/api/v1/campaign/list", {
        params: {
          brand_id: activeBrandID,
          status: status,
          limit: limit,
          offset: pageParam * limit,
          include_total_count: includeTotalCount,
        },
      });
      return data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.has_more ? lastPageParam + 1 : undefined,
  });
};

export default useGetCampaigns;
