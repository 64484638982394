import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import { CheckboxWithQuantityProps } from "../../../../components/campaign/wizard/inputs/CheckboxWithQuantity";
import {
  ChannelType,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import { CampaignType, ItemSetType } from "@openapi";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MailIcon } from "~/assets/icons";
import MetaLogo from "~/components/logos/MetaLogo";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

export const NewCampaignChannelCountDefaults: Record<
  CampaignType,
  {
    [key in ChannelType]: number;
  }
> = {
  [CampaignType.inventory_clearance]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
  [CampaignType.new_products]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 2,
  },
  [CampaignType.sales_event]: {
    [ChannelType.EMAIL]: 3,
    [ChannelType.META_ADS]: 3,
  },
  [CampaignType.other]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 1,
  },
};

export const RecommendedCampaignChannelCountDefaults: Record<
  ItemSetType,
  {
    [key in ChannelType]: number;
  }
> = {
  [ItemSetType.back_in_stock]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 2,
  },
  [ItemSetType.best_sellers]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.discounted_products]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.hero_product]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 2,
  },
  [ItemSetType.new_products]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.new_hero_product]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 2,
  },
  [ItemSetType.new_collection]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.purchased_together]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.slower_moving_goods]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.slowest_moving_goods]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.trending_products]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 2,
  },
};

const useCampaignChannelProps = () => {
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();
  const dispatch = useCampaignWizardDispatch();
  const {
    wizardType,
    campaignData,
    isWizardStateInitialized,
    isShowingMetaConnect,
  } = useCampaignWizardState();
  const campaignType = campaignData?.campaign_type;
  const { adAccounts, isLoading: isLoadingMeta } =
    useMetaAdAccountsQuery(activeBrandID);
  const isMetaConnected = (adAccounts?.length ?? 0) > 0;
  const [showsMetaConnect, setShowsMetaConnect] =
    useState(isShowingMetaConnect);

  const { campaignRecommendation } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );

  useEffect(() => {
    if (!isWizardStateInitialized) {
      return;
    }
    setShowsMetaConnect(isShowingMetaConnect);
  }, [isWizardStateInitialized]);

  /** Default number of channels, for initial state */
  const recommendedCountMapper: { [key in ChannelType]: number } | undefined =
    useMemo(() => {
      if (wizardType === "NEW") {
        return NewCampaignChannelCountDefaults[campaignType as CampaignType];
      }
      if (!campaignRecommendation) {
        return;
      }
      return RecommendedCampaignChannelCountDefaults[
        campaignRecommendation.item_set_type as ItemSetType
      ];
    }, [campaignRecommendation, wizardType, campaignType]);

  const recommendedCountMapperPlaceholder = recommendedCountMapper ?? {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 1,
  };

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children" | "step">>(
    () => ({
      badgeTitle: "Channel",
      nextButtonProps: {
        disabled: isLoadingMeta && !!campaignData.channels?.meta_ads,
        onClick: () => {
          if (!isMetaConnected && !!campaignData.channels?.meta_ads) {
            dispatch({
              type: "RESET_REQUIRED_STEP",
            });
            setShowsMetaConnect(true);
            return;
          }
          dispatch({
            type: "INCREMENT_STEP",
          });
        },
      },
    }),
    [isMetaConnected, isLoadingMeta, campaignData.channels, dispatch]
  );

  // used for restoring the checked quantities
  const [restoreCheckedQuantities, setRestoreCheckedQuantities] = useState<{
    [key in ChannelType]: number;
  }>(recommendedCountMapperPlaceholder);

  useEffect(() => {
    if (!recommendedCountMapper) {
      return;
    }
    setRestoreCheckedQuantities(recommendedCountMapper);
  }, [recommendedCountMapper]);

  // Apply default channels to initial state
  useEffect(() => {
    if (
      !isWizardStateInitialized ||
      !!campaignData.channels ||
      !recommendedCountMapper
    ) {
      return;
    }

    // only call this initially to set the initial state for channels, once all data is loaded
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        channels: {
          emails: recommendedCountMapper[ChannelType.EMAIL],
          meta_ads: recommendedCountMapper[ChannelType.META_ADS],
          push_notifications: 0,
          sms: 0,
        },
      },
    });
  }, [isWizardStateInitialized, recommendedCountMapper]);

  useEffect(() => {
    // reset channels so that it gets updated to the new recommendations
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        channels: undefined,
      },
    });
  }, [campaignData.campaign_type, dispatch]);

  // handles data passing between the context and the interface
  const checkboxChannelMapper: {
    [key in ChannelType]: {
      quantity: number;
      setQuantity: (quantity: number) => void;
    };
  } = useMemo(() => {
    const channels = campaignData.channels ?? {
      emails: recommendedCountMapper?.[ChannelType.EMAIL] ?? 1,
      meta_ads: recommendedCountMapper?.[ChannelType.META_ADS] ?? 1,
      push_notifications: 0,
      sms: 0,
    };
    return {
      [ChannelType.EMAIL]: {
        quantity: channels.emails,
        setQuantity: (quantity) => {
          dispatch({
            type: "UPDATE_CAMPAIGN_DATA",
            payload: {
              channels: {
                ...channels,
                emails: quantity,
              },
            },
          });
        },
      },
      [ChannelType.META_ADS]: {
        quantity: channels.meta_ads ?? 0,
        setQuantity: (quantity) => {
          dispatch({
            type: "UPDATE_CAMPAIGN_DATA",
            payload: {
              channels: {
                ...channels,
                meta_ads: quantity,
              },
            },
          });
        },
      },
    };
  }, [campaignData.channels, recommendedCountMapper]);

  const checkboxProps = useMemo<CheckboxWithQuantityProps[]>(
    () =>
      Object.values(ChannelType).map((c) => {
        const channelMapper = checkboxChannelMapper[c];
        return {
          checked: channelMapper.quantity > 0,
          setChecked: (checked) => {
            if (!checked) {
              setRestoreCheckedQuantities((prev) => ({
                ...prev,
                [c]:
                  channelMapper.quantity > 0
                    ? channelMapper.quantity
                    : recommendedCountMapper?.[c] ?? 1,
              }));
              channelMapper.setQuantity(0);
            } else {
              channelMapper.setQuantity(restoreCheckedQuantities[c]);
            }
          },
          quantity: channelMapper.quantity,
          setQuantity: (quantity) => {
            if (quantity === 0) {
              setRestoreCheckedQuantities((prev) => ({
                ...prev,
                [c]:
                  channelMapper.quantity > 0
                    ? channelMapper.quantity
                    : recommendedCountMapper?.[c] ?? 1,
              }));
            }
            channelMapper.setQuantity(quantity);
          },
          label: c,
          icon:
            c === ChannelType.EMAIL ? (
              <MailIcon />
            ) : (
              <MetaLogo color="rgb(92, 88, 88)" size={24} />
            ),
        };
      }),
    [checkboxChannelMapper, recommendedCountMapper]
  );

  const metaProps = useMemo(() => {
    return {
      showsMetaConnect,
      setShowsMetaConnect,
    };
  }, [showsMetaConnect, setShowsMetaConnect]);

  return {
    cardProps,
    checkboxProps,
    campaignRecommendation,
    metaProps,
    recommendedCountMapper: recommendedCountMapperPlaceholder,
  };
};

export default useCampaignChannelProps;
