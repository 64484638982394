import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import { CampaignCardCommonProps } from "./CampaignCards";
import useCampaignDetailsProps from "./hooks/useCampaignDetailsProps";
import { CampaignType } from "@openapi";
import { Flex, Text, TextArea } from "@radix-ui/themes";
import { PencilIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";

const CampaignDetails: React.FC<CampaignCardCommonProps> = (props) => {
  const {
    campaignData: { campaign_type },
  } = useCampaignWizardState();
  const { cardProps, inputProps } = useCampaignDetailsProps();

  const text =
    campaign_type === CampaignType.other
      ? ""
      : campaign_type === CampaignType.sales_event
      ? "Sales Event"
      : campaign_type === CampaignType.inventory_clearance
      ? "Inventory Clearance"
      : campaign_type === CampaignType.new_products
      ? "New Products"
      : "";

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle={`Tell us in a few words what this ${text} campaign is about`}
      {...props}
    >
      {inputProps.isEditing ? (
        <Flex direction="column" gap="8px">
          <TextArea
            placeholder="Give a brief description of your campaign"
            radius="large"
            size="3"
            color="gray"
            value={inputProps.value}
            onChange={(e) => {
              inputProps.onChange(e);
            }}
            style={{ width: "100%", height: "136px" }}
          />
          {inputProps.value?.trim().length < 30 && (
            <Text size="2" style={{ alignSelf: "flex-end", color: "#666" }}>
              {30 - (inputProps.value?.trim().length || 0)} more characters
            </Text>
          )}
        </Flex>
      ) : (
        <Flex direction="column" gap="24px" align="end" width="100%">
          <Text
            style={{
              backgroundColor: "#F0EDEB",
              padding: "12px 16px",
              borderRadius: "24px",
              borderBottomRightRadius: "0px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "608px",
            }}
          >
            {inputProps.value}
          </Text>
          <AppButton
            onClick={() => inputProps.setIsEditing(true)}
            style={{ width: "max-content" }}
            variant="outlined"
            radius="large"
          >
            <PencilIcon /> Edit
          </AppButton>
        </Flex>
      )}
    </CampaignWizardCard>
  );
};

export default CampaignDetails;
