import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type InternBrandEmailResponse =
  operations["intern_api_get_brand_email_analysis"]["responses"][200]["content"]["application/json"];

const useInternBrandEmailQuery = (brandId: string) => {
  const {
    data: brandEmailData,
    isLoading,
    isSuccess,
  } = useQuery<InternBrandEmailResponse>({
    queryKey: ["internBrandEmail", brandId],
    queryFn: async (): Promise<InternBrandEmailResponse> => {
      const { data } = await axios.get(
        `/api/v1/intern/brand/${brandId}/email-analysis`
      );
      return data;
    },
  });

  return {
    brandEmailData,
    isLoading,
    isSuccess,
  };
};

export default useInternBrandEmailQuery;
