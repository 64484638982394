import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import BrandCustomSectionPropertiesModifier from "./modifiers/BrandCustomSectionPropertiesModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import CollectionModifier from "./modifiers/CollectionModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ImageModifier from "./modifiers/ImageModifier";
import RadiusModifier from "./modifiers/RadiusModfier";
import TextModifier from "./modifiers/TextModifier";
import { EmailElementType } from "@openapi";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionElementMutation from "~/hooks/emails/useInitEmailSectionElementQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isCollectionElement } from "~/utils/emails/elementTypeCheck";
import { isHeroCollectionSection } from "~/utils/emails/useSectionTypeCheck";

const HeroCollectionView = () => {
  const updateSection = useUpdateSectionField();
  const activeBrandId = useActiveBrandID();
  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  const { mutate: initEmailSectionElement } =
    useInitEmailSectionElementMutation({
      onSuccess: (data) => {
        if (!isCollectionElement(data[0])) {
          console.log("Error: Data should represent a collection element");
          return;
        }
        updateSection({
          sectionId: selectedSectionId,
          field: "collection",
          value: data[0],
        });
      },
    });

  if (!selectedSection) {
    return null;
  }

  if (!isHeroCollectionSection(selectedSection)) {
    // Shouldnt get here as we generate directly in AddBlockView
    return null;
  }

  const collection = selectedSection.collection;
  const title = collection.title;
  const description = collection.description;
  const ctaButton = collection.cta_button;
  const image = collection.background_image;
  return (
    <>
      <GeneratedVariantSelector />

      <BrandCustomSectionPropertiesModifier />

      <CollectionModifier
        collections={collection.commerce_platform_item_id ? [collection] : []}
        onCollectionChange={(collections) => {
          initEmailSectionElement({
            brand_id: activeBrandId,
            element_type: EmailElementType.collection,
            palette: selectedSection.palette,
            collection_element_defaults: collections.map((c) => {
              const existingCollectionElement =
                collection.commerce_platform_item_id === c ? collection : null;
              return { ...existingCollectionElement, commerce_platform_id: c };
            }),
          });
        }}
        min={1}
        max={1}
      />

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="collection.title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.title.enabled",
            value: enabled,
          });
        }}
      />
      <TextModifier
        textElement={description}
        label="Description"
        enabled={description.enabled ?? false}
        fieldName="description.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.description.enabled",
            value: enabled,
          });
        }}
      />

      <ButtonModifier
        fieldName="collection.cta_button"
        buttonElement={ctaButton}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.cta_button.enabled",
            value: enabled,
          });
        }}
      />

      <ImageModifier
        collectionId={collection.commerce_platform_item_id}
        initialImageUrl={image?.image_url}
        onAssetChange={(asset) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.background_image.image_url",
            value: asset.url,
          });
        }}
      />

      <RadiusModifier
        radius={image.border_radius}
        onChange={(value) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.background_image.border_radius",
            value: value,
          });
        }}
      />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default HeroCollectionView;
