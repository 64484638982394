import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type KlaviyoCampaignLink =
  operations["emails_api_get_klaviyo_campaign_link"]["responses"][200]["content"]["application/json"];

const useKlaviyoLinkQuery = ({
  emailCreativeId,
}: {
  emailCreativeId?: string | null;
}) => {
  return useQuery({
    queryKey: ["emailCreatives"],
    queryFn: async (): Promise<KlaviyoCampaignLink> => {
      const { data } = await axios.get(
        `/api/v1/emails/email-creative/${emailCreativeId}/klaviyo-link`
      );
      return data;
    },
    staleTime: Infinity,
    enabled: !!emailCreativeId,
  });
};

export default useKlaviyoLinkQuery;
